import { Calender, Category, Dashboard, Doller, Earnings, Users, Verify } from "../../assets/js/CustomIcons";

export const menus = [
  {
    key: "dashboard",
    name: "dashboard",
    path: "/dashboard",
    icon: <Dashboard fontSize={18} color="white" />,
  },
  {
    key: "admins",
    name: "admin",
    path: "/admins",
    icon: <Users fontSize={18} color="white" />,
  },
  {
    key: "users",
    name: "users",
    path: "/users",
    icon: <Users fontSize={18} color="white" />,
  },
  {
    key: "bookings",
    name: "bookings",
    path: "/bookings",
    icon: <Calender fontSize={18} color="white" />,
  },
  {
    key: "categories",
    path: "/categories",
    name: "service category",
    icon: <Category fontSize={18} color="white" />,
  },
  {
    key: "earnings",
    name: "earning",
    path: "/earnings",
    icon: <Earnings fontSize={18} color="white" />,
  },
  {
    path: "/profiles",
    key: "verification",
    name: "profile verification",
    icon: <Verify fontSize={18} color="white" />,
  },
  {
    key: "roles",
    name: "roles",
    path: "/roles",
    icon: <Users fontSize={18} color="white" />,
  },
  {
    key: "subscriptions",
    name: "subscriptions",
    path: "/subscriptions",
    icon: <Doller fontSize={18} color="white" />,
  },
  {
    key: "transactions",
    name: "transactions",
    path: "/transactions",
    icon: <Doller fontSize={18} color="white" />,
  },
  {
    key: "withdrawal",
    name: "withdrawals",
    path: "/withdrawals",
    icon: <Doller fontSize={18} color="white" />,
  },
  {
    key: "adminRequests",
    name: "admin request",
    path: "/admin-request",
    icon: <Users fontSize={18} color="white" />,
  },
]