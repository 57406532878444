import { hideDialog, toastError } from "../../../redux/guestSlices/other.slice"
import { Fragment, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../../../assets/js/muiIcons"
import CustomSelect from "../../ui/CustomSelect"
import CustomInput from "../../ui/CustomInput"
import { useParams } from "react-router-dom"

import {
  handleCreateCategoryReq,
  handleGetAllCategoriesReq
} from "../../../redux/adminSlices/category.slice"

import {
  Button, CircularProgress, DialogActions,
  DialogContent, DialogTitle, Grid2 as Grid,
  IconButton, InputLabel, Paper, Skeleton, styled
} from "@mui/material"

const Paragraph = styled("p")(() => ({}))

function AddSubCategory() {
  const dispatch = useDispatch()
  const parentCategory = useRef("")
  const { id: categoryId } = useParams()
  const [categories, setCategories] = useState([])
  const categorySlice = useSelector(state => state.categorySlice)

  const handleClose = () => {
    dispatch(hideDialog())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    formData.parentCategory = categoryId || parentCategory?.current?.value?._id
    if ([formData?.name, formData?.parentCategory].some(
      (field) => field?.trim() === "" || !field
    )) {
      return dispatch(toastError("Name and sub category is required!"))
    }
    dispatch(handleCreateCategoryReq(formData))
  }

  useEffect(() => {
    if (!categoryId) {
      dispatch(handleGetAllCategoriesReq({
        page: null,
        search: null
      })
      ).unwrap().then((data) => {
        setCategories(data?.categories)
      })
    }

    // eslint-disable-next-line
  }, [])

  return (
    <Paper component="form" onSubmit={handleSubmit}>
      <DialogTitle sx={{ position: "relative" }}>
        <Paragraph
          sx={{
            m: "0",
            fontSize: "20px",
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
        >Add sub category
        </Paragraph>
        <IconButton
          sx={{
            top: "10px",
            right: "10px",
            color: "black",
            position: "absolute",
          }}
          size="small"
          onClick={handleClose}
        ><MuiIcons.ClearRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="relative"
        sx={{
          ".MuiInputBase-root": { borderRadius: "6px" },
        }}
      >
        <Grid container spacing={2}>
          <Grid size={12} sx={{ "input": { p: "2px" } }}>
            <CustomInput
              type="text"
              name="name"
              label="Name"
            />
          </Grid>
          {!categoryId &&
            <Grid size={12}>
              {categorySlice?.categoriesLoading ?
                <Fragment>
                  <InputLabel
                    sx={{
                      mb: 0.5,
                      color: "#333333",
                      display: "block",
                      fontSize: "14px",
                      fontWeight: "400",
                      textAlign: "start",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                    }}
                  >Select parent category
                  </InputLabel>
                  <Skeleton
                    sx={{
                      width: "100%",
                      height: "35px",
                      transform: "none"
                    }}
                  />
                </Fragment> :
                <CustomSelect
                  select="Select"
                  ref={parentCategory}
                  options={categories || []}
                  label="Select parent category"
                />
              }
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          sx={{
            px: 2,
            color: "white",
            fontSize: "14px",
            fontWeight: "400",
            boxShadow: "none",
            minWidth: "150px",
            bgcolor: "#0135AD",
            borderRadius: "50px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          type="submit"
          endIcon={categorySlice?.submitLoading && <CircularProgress
            size={20}
            thickness={5}
            sx={{ color: "white" }}
          />}
        >Create
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default AddSubCategory