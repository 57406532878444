import { Box, Skeleton, styled } from "@mui/material"
import { useState } from "react"

const Image = styled("img")(() => ({}))
function CustomImage({ src, wrapperCss, imgCss }) {
  const [loading, setLoading] = useState(true)

  return (
    <Box sx={{ ...wrapperCss }}>
      {loading &&
        <Skeleton
          sx={{
            width: "100%",
            height: "100%",
            transform: "unset",
            borderRadius: "12px"
          }}
        />
      }
      <Image
        src={src}
        sx={{
          display: loading ? "none" : "block",
          ...imgCss
        }}
        onLoad={() => { setLoading(false) }}
      />
    </Box>
  )
}

export default CustomImage