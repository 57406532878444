import { updateDocVerificationReq } from "../../../redux/adminSlices/users.slice"
import { hideDialog } from "../../../redux/guestSlices/other.slice"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../../../assets/js/muiIcons"
import CustomInput from "../../ui/CustomInput"

import {
  Button, CircularProgress, DialogActions,
  DialogContent, DialogTitle, IconButton,
  Paper, styled
} from "@mui/material"

const Paragraph = styled("p")(() => ({}))

function ProfileRejection() {
  const dispatch = useDispatch()
  const { data } = useSelector(state => state.otherSlice)

  const handleClose = () => {
    dispatch(hideDialog())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    formData.status = "rejected"
    // formData.id = data?.userId
    dispatch(updateDocVerificationReq({ id: data?.userId, formData }))
  }

  return (
    <Paper component="form" onSubmit={handleSubmit}>
      <DialogTitle sx={{ position: "relative" }}>
        <Paragraph
          sx={{
            m: "0",
            fontSize: "20px",
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
        >Verification rejection reason
        </Paragraph>
        <IconButton
          sx={{
            top: "10px",
            right: "10px",
            color: "black",
            position: "absolute",
          }}
          size="small"
          onClick={handleClose}
        ><MuiIcons.ClearRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="relative"
        sx={{
          ".MuiInputBase-root": { borderRadius: "6px" },
          "textarea": { resize: "none" },
        }}
      >
        <CustomInput
          label="Reason"
          type="textarea"
          name="rejectionReason"
          placeholder="Type here"
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          sx={{
            px: 2,
            color: "white",
            fontSize: "14px",
            fontWeight: "400",
            boxShadow: "none",
            minWidth: "150px",
            bgcolor: "#0135AD",
            borderRadius: "50px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          type="submit"
          endIcon={!true && <CircularProgress
            size={20}
            thickness={5}
            sx={{ color: "white" }}
          />}
        >Submit
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default ProfileRejection