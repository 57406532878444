import { allAdminRequestReq, allAdminUpdateReq } from "../../../redux/adminSlices/admin.slice"
import { tBodyCell, tHeadCell, typography1 } from "../../../assets/styles/styles"
import { adminRequestTableHeader } from "../../../constants/admin/Tables"
import { Fragment, useEffect, useRef, useState } from "react"
import SearchInput from "../../../components/ui/SearchInput"
import { useDispatch, useSelector } from "react-redux"
import Header from "../../../components/admin/Header"
import { useNavigate } from "react-router-dom"
import moment from "moment"

import {
  Box, Button, Pagination, Skeleton, Stack,
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Typography
} from "@mui/material"

function AdminRequest() {
  const searchRef = useRef("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const adminSlice = useSelector(state => state.adminSlice)

  // Get all admins
  const getAllAdmins = (page = null, search = null) => {
    dispatch(allAdminRequestReq({
      page,
      search
    })).unwrap().then((res) => {
      setData(res)
    })
  }

  // Handle search admins
  let intervalId = null;
  const handleSearch = () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
    intervalId = setTimeout(() => {
      getAllAdmins(null, searchRef?.current?.value?.toLowerCase())
    }, 2000)
  }

  // Handle verify admin login request
  const handleVarifyAdmin = (id) => {
    dispatch(allAdminUpdateReq({ id, formData: { status: true } })
    ).unwrap().then((res) => {
      getAllAdmins()
    })
  }

  useEffect(() => {
    if (!(adminSlice?.admin?.role === "super-admin" || adminSlice?.admin?.permissions?.adminRequests)) {
      navigate("/")
    }
    getAllAdmins()

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Header>
        <Box>
          <SearchInput
            ref={searchRef}
            onChangeFun={handleSearch}
          />
        </Box>
      </Header>

      <Box
        sx={{
          px: { xs: 2, sm: 4 },
          py: { xs: 1, sm: 2 },
          bgcolor: "#f7f8f9",
          width: {
            xs: "calc(100vw - 6px)",
            laptop: "calc(100vw - (250px + 6px))"
          }
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            component="h5"
            sx={typography1}
          >Sub admins
          </Typography>
        </Box>

        <Box sx={{ boxShadow: "0px 0px 7px 0px #20385833", p: 1, borderRadius: "8px" }}>
          <TableContainer
            sx={{
              bgcolor: "white",
              maxWidth: "100%",
              boxShadow: "none",
              borderRadius: "8px",
            }}
            className="custom-scroller-1"
          >
            <Table stickyHeader sx={{ "td,th": { borderBottom: "none" } }}>
              <TableHead
                sx={{
                  "th:last-child": { borderRadius: "0 6px 6px 0" },
                  "th:first-of-type": { borderRadius: "6px 0 0 6px" },
                }}
              >
                <TableRow>
                  {adminRequestTableHeader?.map((cell, i) => (
                    <TableCell
                      key={i}
                      align="left"
                      sx={tHeadCell}
                    >{cell}
                    </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {adminSlice?.loading ?
                  new Array(5)?.fill()?.map((_, i) => (
                    <TableRow key={i}>
                      {new Array(6)?.fill()?.map((_, i) => (
                        <TableCell
                          key={i}
                          align="left"
                          sx={{
                            py: 0.5,
                            minWidth: "100px",
                          }}
                        ><Skeleton sx={{ height: "30px" }} />
                        </TableCell>
                      ))
                      }
                    </TableRow>
                  )) :
                  data?.subAdmins?.map((admin, i) => (
                    <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell, textTransform: "capitalize" }}
                      >{admin?.users[0]?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell }}
                      >{moment(admin?.users[0]?.created).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell, textTransform: "capitalize" }}
                      >{admin?.roleDetails[0]?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell }}
                      >{admin?.deviceId || "--"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          ...tBodyCell,
                          color: admin?.status ? "#1ACA4B" : "#4E5C6F"
                        }}
                      >{admin?.status ? "Verified" : "Not Verified"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ py: 1 }}
                      >
                        {adminSlice?.admin?.role === "super-admin" || adminSlice?.admin?.permissions?.adminRequests?.update ?
                          <Button
                            size="small"
                            sx={{
                              p: "5px",
                              lineHeight: "1",
                              fontSize: "14px",
                              minWidth: "90px",
                              fontWeight: "400",
                              minHeight: "auto",
                              bgcolor: "#0BCB41",
                              borderRadius: "20px",
                              fontFamily: "Poppins",
                              textTransform: "unset",
                              color: "white !important",
                            }}
                            onClick={() => { handleVarifyAdmin(admin?._id) }}
                          >Approve
                          </Button> :
                          <Button
                            size="small"
                            sx={{
                              p: "5px",
                              lineHeight: "1",
                              fontSize: "14px",
                              minWidth: "90px",
                              fontWeight: "400",
                              minHeight: "auto",
                              bgcolor: "#0BCB41",
                              borderRadius: "20px",
                              fontFamily: "Poppins",
                              textTransform: "unset",
                              color: "white !important",
                              // opacity: (singleUser?.sLoading && userId === user?._id) ? "0.7" : "1"
                            }}
                            disabled
                          >Approve
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  ))
                }

                {(!data?.subAdmins?.length && !adminSlice?.loading) &&
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      sx={{
                        py: 1,
                        color: "#110e24",
                        fontSize: "24px",
                        minWidth: "100px",
                        fontWeight: "500",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                    >No results found!
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{
              py: 1,
              gap: "12px",
              display: "flex",
              justifyContent: "space-between",
              borderTop: "1px solid #D7D7D7",
            }}
          >
            <Typography
              sx={{
                ml: 3,
                color: "#333333",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
            >Showing {((data?.pages?.currentPage - 1) * 10 + 1) || 0} to {((data?.pages?.currentPage - 1) * 10 + data?.users?.length) || 0} of {data?.totalUsers || 0} entries
            </Typography>

            {!adminSlice?.loading ?
              <Pagination
                as="div"
                shape="circular"
                color="primary"
                boundaryCount={3}
                count={data?.pages?.totalPages || 1}
                page={+data?.pages?.currentPage || 1}
                onChange={(_, n) => { getAllAdmins(n, searchRef?.current?.value.toLowerCase()) }}
                sx={{
                  ".MuiPagination-ul": { justifyContent: "end" },
                  ".MuiPagination-ul button": {
                    width: "25px",
                    height: "25px",
                    lineHeight: "1",
                    minWidth: "auto",
                    fontFamily: "Poppins",
                  }
                }}
              /> :
              <Stack direction="row" spacing={1}>
                {new Array(6).fill().map((_, i) => (
                  <Skeleton
                    key={i}
                    sx={{
                      width: "30px",
                      height: "30px",
                      transform: "none"
                    }}
                  />
                ))
                }
              </Stack>
            }
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default AdminRequest