import { rejectedProfileTableHeader } from "../../../constants/admin/Tables"
import { tBodyCell, tHeadCell } from "../../../assets/styles/styles"
import moment from "moment"

import {
  Box, Pagination, Skeleton, Stack, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography
} from "@mui/material"
import { Fragment } from "react"

function RejectedProfiles({ data, loading, getUserFun }) {

  return (
    <Fragment>
      <Box sx={{ boxShadow: "0px 0px 7px 0px #20385833", p: 1, borderRadius: "8px" }}>
        <TableContainer
          sx={{
            bgcolor: "white",
            maxWidth: "100%",
            borderRadius: "8px",
            boxShadow: "none",
            borderTopLeftRadius: "0",
          }}
          className="custom-scroller-1"
        >
          <Table stickyHeader sx={{ "td,th": { borderBottom: "none" } }}>
            <TableHead
              sx={{
                "th:last-child": { borderRadius: "0 6px 6px 0" },
                "th:first-of-type": { borderRadius: "6px 0 0 6px" },
              }}
            >
              <TableRow>
                {rejectedProfileTableHeader?.map((cell, i) => (
                  <TableCell
                    key={i}
                    align="left"
                    sx={tHeadCell}
                  >{cell}
                  </TableCell>
                ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ?
                new Array(5)?.fill()?.map((_, i) => (
                  <TableRow key={i}>
                    {new Array(4)?.fill()?.map((_, i) => (
                      <TableCell
                        key={i}
                        align="left"
                        sx={{
                          py: 0.5,
                          minWidth: "100px",
                        }}
                      ><Skeleton sx={{ height: "30px" }} />
                      </TableCell>
                    ))
                    }
                  </TableRow>
                )) :
                data?.users?.map((user, i) => (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    <TableCell
                      align="left"
                      sx={{ ...tBodyCell, textTransform: "capitalize" }}
                    >{user?.fullName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ ...tBodyCell, textTransform: "capitalize" }}
                    >{moment(user?.createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        ...tBodyCell,
                        color: "#4E5C6F"
                      }}
                    >Rejected
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ ...tBodyCell }}
                    >{user?.rejectionReason}
                    </TableCell>
                  </TableRow>
                ))
              }

              {(!data?.users?.length && !loading) &&
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      py: 1,
                      color: "#110e24",
                      fontSize: "24px",
                      minWidth: "100px",
                      fontWeight: "500",
                      maxWidth: "250px",
                      textWrap: "nowrap",
                      overflow: "hidden",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                    }}
                  >No results found!
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            py: 1,
            gap: "12px",
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px solid #D7D7D7",
          }}
        >
          <Typography
            sx={{
              ml: 3,
              color: "#333333",
              fontSize: "14px",
              fontWeight: "400",
              // textWrap: "nowrap",
              fontFamily: "Poppins",
            }}
          >Showing {((data?.pages?.currentPage - 1) * 10 + 1) || 0} to {((data?.pages?.currentPage - 1) * 10 + data?.users?.length) || 0} of {(data?.totalUsers) || 0} entries
          </Typography>

          {!loading ?
            <Pagination
              as="div"
              color="primary"
              shape="circular"
              boundaryCount={3}
              count={data?.pages?.totalPages || 1}
              page={+data?.pages?.currentPage || 1}
              onChange={(_, n) => { getUserFun(n) }}
              sx={{
                ".MuiPagination-ul": { justifyContent: "end" },
                ".MuiPagination-ul button": {
                  width: "25px",
                  height: "25px",
                  lineHeight: "1",
                  minWidth: "auto",
                  fontFamily: "Poppins",
                }
              }}
            /> :
            <Stack direction="row" spacing={1}>
              {new Array(6).fill().map((_, i) => (
                <Skeleton
                  key={i}
                  sx={{
                    width: "30px",
                    height: "30px",
                    transform: "none"
                  }}
                />
              ))
              }
            </Stack>
          }
        </Box>
      </Box>
    </Fragment>
  )
}

export default RejectedProfiles