import { PersistGate } from "redux-persist/integration/react"
import { RouterProvider } from "react-router-dom"
import { ThemeProvider } from "@mui/material"
import { persistStore } from "redux-persist"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import router from "./routes/routes"
import theme from "./utils/muiTheme"
import './assets/styles/global.css'
import store from "./redux/store"

const persistedStore = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </PersistGate>
  </Provider>
)