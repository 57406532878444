import { handleGetSingleCategoryReq, toggleCategoryStatusReq } from "../../../redux/adminSlices/category.slice"
import { showDialog, showDialogWithData } from "../../../redux/guestSlices/other.slice"
import { tBodyCell, tHeadCell, typography1 } from "../../../assets/styles/styles"
import { subCategoriesTableHeader } from "../../../constants/admin/Tables"
import CustomSwitch from "../../../components/ui/CustomSwitch"
import { Fragment, useEffect, useRef, useState } from "react"
import { Delete, Edit } from "../../../assets/js/CustomIcons"
import SearchInput from "../../../components/ui/SearchInput"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Header from "../../../components/admin/Header"
import MuiIcons from "../../../assets/js/muiIcons"

import {
  Box, Button, CircularProgress, IconButton, Pagination,
  Skeleton, Stack, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography
} from "@mui/material"

function SingleCategory() {
  const loading = true
  const singleUser = {}
  const searchRef = useRef("")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState({})
  const { id: categoryId } = useParams()
  const { admin } = useSelector(state => state.adminSlice)
  const { refresh } = useSelector(state => state.otherSlice)
  const { category } = useSelector(state => state.categorySlice)

  // Add category
  const handleAddSubCategory = () => {
    dispatch(showDialog("AddSubCategory"))
  }

  // Edit category
  const handleEditCategory = (category) => {
    dispatch(showDialogWithData({ type: "EditCategory", data: { category } }))
  }

  // Handle user status
  const handleStatus = (e, id) => {
    dispatch(toggleCategoryStatusReq({
      id, status: e.target.checked,
    })).unwrap().then(() => {
      getAllSubCategories()
    })
  }

  // Delete category
  const handleDeleteCategory = (categoryId) => {
    dispatch(showDialogWithData({ type: "DeleteCategory", data: { categoryId } }))
  }

  // Get all sub categories
  const getAllSubCategories = (page = null, search = null) => {
    dispatch(handleGetSingleCategoryReq({
      page,
      search,
      id: categoryId
    })).unwrap().then((res) => {
      setData(res)
    })
  }

  // Handle search
  let intervalId = null;
  const handleSearch = () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
    intervalId = setTimeout(() => {
      getAllSubCategories(null, searchRef?.current?.value)
    }, 2000)
  }

  useEffect(() => {
    if (!(admin?.role === "super-admin" || admin?.permissions?.categories)) {
      return navigate("/")
    }
    getAllSubCategories()

    // eslint-disable-next-line
  }, [refresh])

  return (
    <Fragment>
      <Header>
        <Box>
          <SearchInput
            ref={searchRef}
            onChangeFun={handleSearch}
          />
        </Box>
      </Header>

      <Box
        sx={{
          px: { xs: 2, sm: 4 },
          py: { xs: 1, sm: 2 },
          bgcolor: "#f7f8f9",
          width: {
            xs: "calc(100vw - 6px)",
            laptop: "calc(100vw - (250px + 6px))"
          }
        }}
      >

        <Box
          sx={{
            mb: 2,
            display: "flex",
            alignItems: { xs: "start", sm: "center" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Typography
            component="h5"
            sx={typography1}
          >{data?.name}
          </Typography>
          <Button
            sx={{
              px: 2,
              py: "6px",
              ml: "auto",
              color: "white",
              fontSize: "14px",
              lineHeight: "1",
              fontWeight: "300",
              boxShadow: "none",
              bgcolor: "#0135AD",
              borderRadius: "50px",
              fontFamily: "Poppins",
              textTransform: "none",
              ".MuiButton-icon": { mr: "0" }
            }}
            onClick={handleAddSubCategory}
            startIcon={<MuiIcons.AddRounded />}
          >Add sub category
          </Button>
        </Box>

        <Box sx={{ boxShadow: "0px 0px 7px 0px #20385833", p: 1, borderRadius: "8px" }}>
          <TableContainer
            sx={{
              bgcolor: "white",
              maxWidth: "100%",
              boxShadow: "none",
              borderRadius: "8px",
            }}
            className="custom-scroller-1"
          >
            <Table stickyHeader sx={{ "td,th": { borderBottom: "none" } }}>
              <TableHead
                sx={{
                  "th:last-child": { borderRadius: "0 6px 6px 0" },
                  "th:first-of-type": { borderRadius: "6px 0 0 6px" },
                }}
              >
                <TableRow>
                  {subCategoriesTableHeader?.map((cell, i) => (
                    <TableCell
                      key={i}
                      align="left"
                      sx={tHeadCell}
                    >{cell}
                    </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {category?.loading ?
                  new Array(5)?.fill()?.map((_, i) => (
                    <TableRow key={i}>
                      {new Array(3)?.fill()?.map((_, i) => (
                        <TableCell
                          key={i}
                          align="left"
                          sx={{
                            py: 0.5,
                            minWidth: "100px",
                          }}
                        ><Skeleton sx={{ height: "30px" }} />
                        </TableCell>
                      ))
                      }
                    </TableRow>
                  )) :
                  data?.categories?.map((category, i) => (
                    <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                      <TableCell
                        align="left"
                        sx={tBodyCell}
                      >{category?.name}
                      </TableCell>
                      <TableCell sx={{ py: "0" }}>
                        {(singleUser?.sLoading && loading === category?._id) ?
                          <CircularProgress
                            size={25}
                            thickness={6}
                            sx={{
                              color: "#00cada",
                              verticalAlign: "middle",
                              "span": { verticalAlign: "middle" }
                            }}
                          /> :
                          (admin?.role === "super-admin" || admin?.permissions?.users?.update) ?
                            <CustomSwitch
                              checked={category?.status}
                              onChange={(e) => { handleStatus(e, category?._id) }}
                            /> :
                            <CustomSwitch
                              disabled
                              checked={category?.status}
                            />
                        }
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell, py: "0" }}
                      >
                        {(admin?.role === "super-admin" || admin?.permissions?.users?.update) ?
                          <IconButton
                            size="small"
                            onClick={() => { handleEditCategory(category) }}
                          ><Edit />
                          </IconButton> :
                          <IconButton
                            disabled
                            size="small"
                          ><Edit />
                          </IconButton>
                        }
                        {(admin?.role === "super-admin" || admin?.permissions?.users?.delete) ?
                          <IconButton
                            size="small"
                            onClick={() => { handleDeleteCategory(category?._id) }}
                          ><Delete />
                          </IconButton> :
                          <IconButton
                            disabled
                            size="small"
                          ><Delete />
                          </IconButton>
                        }
                      </TableCell>
                    </TableRow>
                  ))
                }

                {(!data?.categories?.length && !category?.loading) &&
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      sx={{
                        py: 1,
                        color: "#110e24",
                        fontSize: "24px",
                        minWidth: "100px",
                        fontWeight: "500",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                    >No results found!
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{
              py: 1,
              gap: "12px",
              display: "flex",
              justifyContent: "space-between",
              borderTop: "1px solid #D7D7D7",
            }}
          >
            <Typography
              sx={{
                ml: 3,
                color: "#333333",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
            >Showing {((data?.pages?.currentPage - 1) * 10 + 1) || 0} to {((data?.pages?.currentPage - 1) * 10 + data?.categories?.length) || 0} of {data?.totalCategories || 0} entries
            </Typography>

            {!category?.loading ?
              <Pagination
                as="div"
                shape="circular"
                color="primary"
                boundaryCount={3}
                count={data?.pages?.totalPages || 1}
                page={+data?.pages?.currentPage || 1}
                onChange={(_, n) => { getAllSubCategories(n, searchRef?.current?.value) }}
                sx={{
                  ".MuiPagination-ul": { justifyContent: "end" },
                  ".MuiPagination-ul button": {
                    width: "25px",
                    height: "25px",
                    lineHeight: "1",
                    minWidth: "auto",
                    fontFamily: "Poppins",
                  }
                }}
              /> :
              <Stack direction="row" spacing={1}>
                {new Array(6).fill().map((_, i) => (
                  <Skeleton
                    key={i}
                    sx={{
                      width: "30px",
                      height: "30px",
                      transform: "none"
                    }}
                  />
                ))
                }
              </Stack>
            }
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default SingleCategory