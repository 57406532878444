import { updateProfileStatusReq } from "../../../redux/adminSlices/users.slice"
import { pendingProfileTableHeader } from "../../../constants/admin/Tables"
import { showDialogWithData } from "../../../redux/guestSlices/other.slice"
import { tBodyCell, tHeadCell } from "../../../assets/styles/styles"
import DocViewer from "../../../components/common/dialogs/DocViewer"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useEffect, useState } from "react"
import moment from "moment"

import {
  Box, Button, Pagination, Skeleton, Stack, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography
} from "@mui/material"

function PendingProfiles({ data, loading, getUserFun }) {
  const dispatch = useDispatch()
  const [src, setSrc] = useState("")
  const [pdf, setPdf] = useState("")
  const [open, setOpen] = useState(false)
  const [userId, setUserId] = useState("671f1c4ece7cedfa9034c620")
  const { singleUser } = useSelector(state => state.usersSlice)

  // Show doc dialog
  const handleShowDoc = (i) => {
    setOpen(true)
    if (i % 2 === 0) {
      setSrc("https://plus.unsplash.com/premium_photo-1678566111481-8e275550b700?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
      setPdf("")
    } else {
      setSrc("")
      setPdf("https://www.orimi.com/pdf-test.pdf")
    }
  }

  // Handle reject verification (Show rejection reason dialog)
  const handleReject = (id) => {
    dispatch(showDialogWithData({ type: "VerificationRejection", data: { userId: id } }))
  }

  // Handle verification
  const handleVarifyUser = (id,) => {
    setUserId(id)
    dispatch(updateProfileStatusReq(
      { id, status: "verified", rejectionReason: null })).unwrap(
    ).then(() => {
      getUserFun()
    }).finally(() => {
      setUserId("")
    })
  }

  useEffect(() => {
    getUserFun()

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Box sx={{ boxShadow: "0px 0px 7px 0px #20385833", p: 1, borderRadius: "8px" }}>
        <TableContainer
          sx={{
            bgcolor: "white",
            maxWidth: "100%",
            boxShadow: "none",
            borderRadius: "8px",
          }}
          className="custom-scroller-1"
        >
          <Table stickyHeader sx={{ "td,th": { borderBottom: "none" } }}>
            <TableHead
              sx={{
                "th:last-child": { borderRadius: "0 6px 6px 0" },
                "th:first-of-type": { borderRadius: "6px 0 0 6px" },
              }}
            >
              <TableRow>
                {pendingProfileTableHeader?.map((cell, i) => (
                  <TableCell
                    key={i}
                    align="left"
                    sx={tHeadCell}
                  >{cell}
                  </TableCell>
                ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ?
                new Array(5)?.fill()?.map((_, i) => (
                  <TableRow key={i}>
                    {new Array(4)?.fill()?.map((_, i) => (
                      <TableCell
                        key={i}
                        align="left"
                        sx={{
                          py: 0.5,
                          minWidth: "100px",
                        }}
                      ><Skeleton sx={{ height: "30px" }} />
                      </TableCell>
                    ))
                    }
                  </TableRow>
                )) :
                data?.users?.map((user, i) => (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    <TableCell
                      align="left"
                      sx={{ ...tBodyCell, textTransform: "capitalize" }}
                    >{user?.fullName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={tBodyCell}
                    >{moment(user?.createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    >
                      <Button
                        sx={{
                          p: "5px",
                          lineHeight: "1",
                          color: "#0135AD",
                          fontSize: "14px",
                          fontWeight: "400",
                          minHeight: "auto",
                          borderRadius: "20px",
                          fontFamily: "Poppins",
                          textTransform: "unset",
                          borderColor: "#0135AD",
                        }}
                        size="small"
                        variant="outlined"
                        onClick={() => { handleShowDoc(i) }}
                      >View
                      </Button>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    >
                      <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                        <Button
                          size="small"
                          sx={{
                            p: "5px",
                            lineHeight: "1",
                            fontSize: "14px",
                            minWidth: "90px",
                            fontWeight: "400",
                            minHeight: "auto",
                            bgcolor: "#0BCB41",
                            borderRadius: "20px",
                            fontFamily: "Poppins",
                            textTransform: "unset",
                            color: "white !important",
                            opacity: (singleUser?.sLoading && userId === user?._id) ? "0.7" : "1"
                          }}
                          disabled={singleUser?.sLoading && userId === user?._id}
                          onClick={() => { handleVarifyUser(user?._id) }}
                        >Verify
                        </Button>
                        <Button
                          size="small"
                          sx={{
                            p: "5px",
                            color: "white",
                            lineHeight: "1",
                            fontSize: "14px",
                            minWidth: "90px",
                            fontWeight: "400",
                            minHeight: "auto",
                            bgcolor: "#E40606",
                            borderRadius: "20px",
                            fontFamily: "Poppins",
                            textTransform: "unset",
                          }}
                          onClick={() => { handleReject(user?._id) }}
                        >Reject
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              }

              {(!data?.users?.length && !loading) &&
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      py: 1,
                      color: "#110e24",
                      fontSize: "24px",
                      minWidth: "100px",
                      fontWeight: "500",
                      maxWidth: "250px",
                      textWrap: "nowrap",
                      overflow: "hidden",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                    }}
                  >No results found!
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            py: 1,
            gap: "12px",
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px solid #D7D7D7",
          }}
        >
          <Typography
            sx={{
              ml: 3,
              color: "#333333",
              fontSize: "14px",
              fontWeight: "400",
              // textWrap: "nowrap",
              fontFamily: "Poppins",
            }}
          >Showing {((data?.pages?.currentPage - 1) * 10 + 1) || 0} to {((data?.pages?.currentPage - 1) * 10 + data?.users?.length) || 0} of {(data?.totalUsers) || 0} entries
          </Typography>

          {!loading ?
            <Pagination
              as="div"
              color="primary"
              shape="circular"
              boundaryCount={3}
              count={data?.pages?.totalPages || 4}
              page={+data?.pages?.currentPage || 1}
              onChange={(_, n) => { getUserFun(n) }}
              sx={{
                ".MuiPagination-ul": { justifyContent: "end" },
                ".MuiPagination-ul button": {
                  width: "25px",
                  height: "25px",
                  lineHeight: "1",
                  minWidth: "auto",
                  fontFamily: "Poppins",
                }
              }}
            /> :
            <Stack direction="row" spacing={1}>
              {new Array(6).fill().map((_, i) => (
                <Skeleton
                  key={i}
                  sx={{
                    width: "30px",
                    height: "30px",
                    transform: "none"
                  }}
                />
              ))
              }
            </Stack>
          }
        </Box>
      </Box>

      <DocViewer open={open} setOpen={setOpen} src={src} pdf={pdf} />
    </Fragment>
  )
}

export default PendingProfiles