import { hideDialog, refresher, toastError } from "../../../redux/guestSlices/other.slice"
import { handleCreateRoleReq } from "../../../redux/adminSlices/role.slice"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../../../assets/js/muiIcons"
import CustomInput from "../../ui/CustomInput"

import {
  Button, CircularProgress, DialogActions, DialogContent,
  DialogTitle, Grid2 as Grid, IconButton, Paper, styled
} from "@mui/material"

const Paragraph = styled("p")(() => ({}))

function CreateRole() {
  const dispatch = useDispatch()
  const { role } = useSelector(state => state.roleSlice)

  // Close dialog
  const handleClose = () => {
    dispatch(hideDialog())
  }

  // Handle create role
  const handleCreate = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    if (!formData.name) {
      return dispatch(toastError("Name is required!"))
    }
    dispatch(handleCreateRoleReq(formData)
    ).unwrap().then(() => {
      setTimeout(() => {
        handleClose()
        dispatch(refresher())
      }, 500)
    })
  }

  return (
    <Paper component="form" onSubmit={handleCreate}>
      <DialogTitle sx={{ position: "relative" }}>
        <Paragraph
          sx={{
            m: "0",
            fontSize: "20px",
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
        >Add new role
        </Paragraph>
        <IconButton
          sx={{
            top: "10px",
            right: "10px",
            color: "black",
            position: "absolute",
          }}
          size="small"
          onClick={handleClose}
        ><MuiIcons.ClearRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="relative"
        sx={{
          ".MuiInputBase-root": { borderRadius: "6px" },
        }}
      >
        <Grid container spacing={2}>
          <Grid size={12} sx={{ "input": { p: "2px" } }}>
            <CustomInput
              type="text"
              name="name"
              label="Name"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          sx={{
            px: 2,
            color: "white",
            fontSize: "14px",
            fontWeight: "400",
            boxShadow: "none",
            minWidth: "150px",
            bgcolor: "#0135AD",
            borderRadius: "50px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          type="submit"
          endIcon={role?.loadingCreate &&
            <CircularProgress
              size={20}
              thickness={5}
              sx={{ color: "white" }}
            />
          }
        >Create
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default CreateRole