export const typography1 = {
  mb: 2,
  color: "black",
  fontSize: "22px",
  fontWeight: "500",
  textWrap: "nowrap",
  lineHeight: "33px",
  fontFamily: "Poppins",
}
export const typography2 = {
  color: "black",
  fontSize: "18px",
  fontWeight: "500",
  textWrap: "nowrap",
  lineHeight: "27px",
  fontFamily: "Poppins",
}
export const storeBtn = {
  px: "4px",
  py: "2px",
  gap: "4px",
  display: "flex",
  bgcolor: "white",
  borderRadius: "4px",
  alignItems: "center",
  border: "1px solid #E4E4E4",
}
export const tHeadCell = {
  py: 1,
  color: "black",
  fontSize: "14px",
  minWidth: "100px",
  fontWeight: "400",
  textWrap: "nowrap",
  lineHeight: "21px",
  bgcolor: "#F3F3F3",
  fontFamily: "Poppins",
}
export const tBodyCell = {
  py: 1,
  color: "#4E5C6F",
  fontSize: "14px",
  minWidth: "100px",
  fontWeight: "400",
  lineHeight: "21px",
  textWrap: "nowrap",
  fontFamily: "Poppins",
}