import { handleDeleteSingleCategoryReq } from "../../../redux/adminSlices/category.slice"
import { hideDialog } from "../../../redux/guestSlices/other.slice"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../../../assets/js/muiIcons"
import { useParams } from "react-router-dom"

import {
  Box, Button, CircularProgress, DialogActions,
  DialogContent, DialogTitle, IconButton,
  Paper, styled
} from "@mui/material"

const Paragraph = styled("p")(() => ({}))

function DeleteCategory() {
  const dispatch = useDispatch()
  const { id: categoryId } = useParams()
  const { data } = useSelector(state => state.otherSlice)
  const categorySlice = useSelector(state => state.categorySlice)

  const handleClose = () => {
    dispatch(hideDialog())
  }

  const handleDelete = () => {
    dispatch(handleDeleteSingleCategoryReq(data?.categoryId))
  }

  return (
    <Paper>
      <DialogTitle sx={{ position: "relative" }}>
        <Paragraph
          sx={{
            m: "0",
            fontSize: "20px",
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
        >Delete category
        </Paragraph>
        <IconButton
          sx={{
            top: "10px",
            right: "10px",
            color: "black",
            position: "absolute",
          }}
          size="small"
          onClick={handleClose}
        ><MuiIcons.ClearRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="relative"
        sx={{
          ".MuiInputBase-root": { borderRadius: "6px" },
        }}
      >
        <Box sx={{ display: "grid", placeContent: "center", mb: 2 }}>
          <MuiIcons.ErrorOutlineRounded color="primary" sx={{ fontSize: "60px" }} />
        </Box>

        <Paragraph
          sx={{
            m: "0",
            color: "#333333",
            fontSize: "14px",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
        >
          {!categoryId ?
            "Warning: Deleting this category will permanently delete all associated subcategories. Are you sure you want to proceed?" :
            "Warning: This category will permanently delete. Are you sure you want to proceed?"
          }
        </Paragraph>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          sx={{
            px: 2,
            fontSize: "14px",
            fontWeight: "400",
            boxShadow: "none",
            minWidth: "150px",
            borderWidth: "2px",
            borderRadius: "50px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          variant="outlined"
          onClick={handleClose}
        >Cancel
        </Button>
        <Button
          sx={{
            px: 2,
            color: "white",
            fontSize: "14px",
            fontWeight: "400",
            boxShadow: "none",
            minWidth: "150px",
            bgcolor: "#0135AD",
            borderRadius: "50px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          endIcon={categorySlice?.submitLoading && <CircularProgress
            size={20}
            thickness={5}
            sx={{ color: "white" }}
          />}
          onClick={handleDelete}
        >Delete
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default DeleteCategory