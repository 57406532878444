import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import ImageWithSkell from "../ImageWithSkell"
import MuiIcons from "../../../assets/js/muiIcons"

function DocViewer({ open, setOpen, src, pdf }) {

  const handleClose = () => {
    setOpen(false)
  }

  const handleDownload = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "downloaded-file";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed", error);
    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      scroll="paper"
      maxWidth="xl"
      onClose={handleClose}
      sx={{ ".MuiPaper-root": { height: "100%" } }}
    >
      <DialogTitle sx={{ position: "relative" }}>
        <IconButton
          sx={{
            top: "0",
            right: "10px",
            color: "black",
            position: "absolute",
          }}
          size="small"
          onClick={handleClose}
        ><MuiIcons.ClearRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {src ?
          <Box sx={{ position: "relative" }}>
            <IconButton
              sx={{
                top: "10px",
                left: "10px",
                position: "absolute",
                color: "primary.main",
                bgcolor: "white !important",
              }}
              size="small"
              onClick={() => { handleDownload(src) }}
            ><MuiIcons.DownloadRounded />
            </IconButton>
            <ImageWithSkell
              src={src}
            />
          </Box> :
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              position: "relative",
              flexDirection: "column",
              "embed": {
                width: "100%",
                height: "100%",
                border: "none",
              }
            }}
          >
            <IconButton
              sx={{
                top: "10px",
                left: "10px",
                position: "absolute",
                color: "primary.main",
                bgcolor: "white !important",
              }}
              size="small"
              onClick={() => { handleDownload(pdf) }}
            ><MuiIcons.DownloadRounded />
            </IconButton>
            <embed
              type="application/pdf"
              src={`${pdf}#toolbar=0&navpanes=0&scrollbar=0`}
            />
          </Box>
        }
      </DialogContent>
    </Dialog>
  )
}

export default DocViewer