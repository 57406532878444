import { combineReducers } from "@reduxjs/toolkit"

// Guest slices
import otherSlice from "./guestSlices/other.slice"

// Admin slices
import roleSlice from "./adminSlices/role.slice"
import adminSlice from "./adminSlices/admin.slice"
import usersSlice from "./adminSlices/users.slice"
import categorySlice from "./adminSlices/category.slice"
import subAdminSlice from "./adminSlices/subAdminReq.slice"
import withdrawalSlice from "./adminSlices/withdrawal.slice"

const rootReducer = combineReducers({
  otherSlice,

  // Admin Slices
  roleSlice,
  usersSlice,
  adminSlice,
  categorySlice,
  subAdminSlice,
  withdrawalSlice,
})

export default rootReducer