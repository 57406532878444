import { OutlinedInput, MenuItem, Select, FormControl, InputLabel } from "@mui/material"
import { Fragment, forwardRef } from "react"
import { uid } from "uid"

const CustomSelect = forwardRef(({ label, name, value, select, options, id, disabled = false, onChngFun }, ref) => {
  if (!id) { id = uid(6) }

  const handleChange = (e) => {
    if (onChngFun) onChngFun(e.target.value?._id)
  }

  return (
    <Fragment>
      {label &&
        <InputLabel
          sx={{
            mb: 0.5,
            color: "#333333",
            display: "block",
            fontSize: "14px",
            fontWeight: "400",
            textAlign: "start",
            fontFamily: "Poppins",
            textTransform: "capitalize",
          }}
          htmlFor={id}
        >{label}
        </InputLabel>
      }
      <FormControl size="small" fullWidth>
        <Select
          name={name}
          labelId={id}
          inputRef={ref}
          disabled={disabled}
          onChange={handleChange}
          input={<OutlinedInput
            id={id}
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#DADADA",
              },
              ".MuiSelect-select": {
                py: "6px",
                fontSize: "14px",
                color: "#999999",
                textTransform: "capitalize"
              }
            }}
          />}
          defaultValue={value || "[object Object]"}
          renderValue={(option) => option.name || select || "Select"}
        >
          <MenuItem
            disabled
            value="[object Object]"
            sx={{
              fontSize: "14px",
              color: "#999999",
            }}
          >{select || "Select"}
          </MenuItem>
          {options.map((option, i) => (
            <MenuItem
              key={i}
              value={option}
              sx={{
                color: "black",
                fontSize: "14px",
                textTransform: "capitalize"
              }}
            >{option?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Fragment>
  )
})

export default CustomSelect