import { toggleUserStatusReq, updateDocVerificationReq } from "../../../redux/adminSlices/users.slice"
import { showDialogWithData } from "../../../redux/guestSlices/other.slice"
import { tBodyCell, tHeadCell } from "../../../assets/styles/styles"
import { userTableHeader } from "../../../constants/admin/Tables"
import CustomSwitch from "../../../components/ui/CustomSwitch"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useState } from "react"
import DocViewer from "./DocViewer"
import moment from "moment"

import {
  Box, Button, CircularProgress, Pagination, Skeleton,
  Stack, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Typography
} from "@mui/material"

function Agencies({ data, getUserFun }) {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [documents, setSocuments] = useState([])
  const [loading, setLoading] = useState({})
  const { admin } = useSelector(state => state.adminSlice)
  const { users, singleUser } = useSelector(state => state.usersSlice)

  // Handle user status
  const handleStatus = (e, id) => {
    setLoading(id)
    dispatch(toggleUserStatusReq({
      id, status: e.target.checked,
    })).unwrap().then(() => {
      getUserFun()
    })
  }

  // Handle reject verification (Show rejection reason dialog)
  const handleReject = (id) => {
    dispatch(showDialogWithData({ type: "ProfileRejection", data: { userId: id } }))
  }

  // Handle show documents
  const handleShowDoc = (docs) => {
    setShow(true)
    setSocuments(docs)
  }

  // Handle doc verification 
  const handleVarifyDoc = (id, status) => {
    dispatch(updateDocVerificationReq({ id, formData: { status } })
    ).unwrap().then(() => {
      getUserFun()
    })
  }

  return (
    <Fragment>
      <Box sx={{ boxShadow: "0px 0px 7px 0px #20385833", p: 1, borderRadius: "8px" }}>
        <TableContainer
          sx={{
            bgcolor: "white",
            maxWidth: "100%",
            boxShadow: "none",
            borderRadius: "8px",
          }}
          className="custom-scroller-1"
        >
          <Table stickyHeader sx={{ "td,th": { borderBottom: "none" } }}>
            <TableHead
              sx={{
                "th:last-child": { borderRadius: "0 6px 6px 0" },
                "th:first-of-type": { borderRadius: "6px 0 0 6px" },
              }}
            >
              <TableRow>
                {userTableHeader?.map((cell, i) => (
                  <TableCell
                    key={i}
                    align="left"
                    sx={tHeadCell}
                  >{cell}
                  </TableCell>
                ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.allLoading ?
                new Array(5)?.fill()?.map((_, i) => (
                  <TableRow key={i}>
                    {new Array(8)?.fill()?.map((_, i) => (
                      <TableCell
                        key={i}
                        align="left"
                        sx={{
                          py: 0.5,
                          minWidth: "100px",
                        }}
                      ><Skeleton sx={{ height: "30px" }} />
                      </TableCell>
                    ))
                    }
                  </TableRow>
                )) :
                data?.users?.map((user, i) => (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    <TableCell
                      align="left"
                      sx={{ ...tBodyCell, textTransform: "capitalize" }}
                    >{user?.fullName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ ...tBodyCell }}
                    >{moment(user?.createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ ...tBodyCell, textTransform: "capitalize" }}
                    >{user?.userRole}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        ...tBodyCell,
                        color: user?.accountStatus === "verified" ? "#1ACA4B" : "#4E5C6F"
                      }}
                    >{user?.accountStatus === "verified" ? "Verified" : "Not Verified"}
                    </TableCell>
                    <TableCell sx={{ py: "0" }}>
                      {(singleUser?.sLoading && loading === user?._id) ?
                        <CircularProgress
                          size={20}
                          thickness={5}
                          sx={{
                            color: "#0135ad",
                            verticalAlign: "middle",
                            "span": { verticalAlign: "middle" }
                          }}
                        /> :
                        (admin?.role === "super-admin" || admin?.permissions?.users?.update) ?
                          <CustomSwitch
                            checked={user?.status}
                            onChange={(e) => { handleStatus(e, user?._id) }}
                          /> :
                          <CustomSwitch
                            disabled
                            checked={user?.status}
                          />
                      }
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ ...tBodyCell }}
                    >{user?.userRole === "buyer" ? "--" : 20}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    >
                      <Button
                        sx={{
                          p: "5px",
                          lineHeight: "1",
                          color: "#0135AD",
                          fontSize: "14px",
                          fontWeight: "400",
                          minHeight: "auto",
                          borderRadius: "20px",
                          fontFamily: "Poppins",
                          textTransform: "unset",
                          borderColor: "#0135AD",
                        }}
                        size="small"
                        variant="outlined"
                        onClick={() => { handleShowDoc(user?.documents) }}
                      >View
                      </Button>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    >
                      {user?.profileStatus === "pending" ?
                        <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                          <Button
                            size="small"
                            sx={{
                              p: "5px",
                              lineHeight: "1",
                              fontSize: "14px",
                              minWidth: "90px",
                              fontWeight: "400",
                              minHeight: "auto",
                              bgcolor: "#0BCB41",
                              borderRadius: "20px",
                              fontFamily: "Poppins",
                              textTransform: "unset",
                              color: "white !important",
                              // opacity: (!user?.documents?.length) ? "0.7" : "1"
                            }}
                            // disabled={!user?.documents?.length}
                            onClick={() => { handleVarifyDoc(user?._id, "verified") }}
                          >Approve
                          </Button>
                          <Button
                            size="small"
                            sx={{
                              p: "5px",
                              lineHeight: "1",
                              fontSize: "14px",
                              minWidth: "90px",
                              fontWeight: "400",
                              minHeight: "auto",
                              bgcolor: "#E40606",
                              borderRadius: "20px",
                              fontFamily: "Poppins",
                              textTransform: "unset",
                              color: "white !important",
                              // opacity: (!user?.documents?.length) ? "0.7" : "1"
                            }}
                            // disabled={!user?.documents?.length}
                            onClick={() => { handleReject(user?._id) }}
                          >Reject
                          </Button>
                        </Stack> :
                        <Typography
                          sx={{
                            ...tBodyCell,
                            textTransform: "capitalize",
                            color: user?.profileStatus === "verified" ? "#1ACA4B" : "#4E5C6F"
                          }}
                          component="span"
                        >{user?.profileStatus}
                        </Typography>

                      }
                    </TableCell>
                  </TableRow>
                ))
              }

              {(!data?.users?.length && !users?.allLoading) &&
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      py: 1,
                      color: "#110e24",
                      fontSize: "24px",
                      minWidth: "100px",
                      fontWeight: "500",
                      maxWidth: "250px",
                      textWrap: "nowrap",
                      overflow: "hidden",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                    }}
                  >No results found!
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            py: 1,
            gap: "12px",
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px solid #D7D7D7",
          }}
        >
          <Typography
            sx={{
              ml: 3,
              color: "#333333",
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Poppins",
            }}
          >Showing {((data?.pages?.currentPage - 1) * 10 + 1) || 0} to {((data?.pages?.currentPage - 1) * 10 + data?.users?.length) || 0} of {data?.totalUsers || 0} entries
          </Typography>

          {!users?.allLoading ?
            <Pagination
              as="div"
              shape="circular"
              color="primary"
              boundaryCount={3}
              count={data?.pages?.totalPages || 1}
              page={+data?.pages?.currentPage || 1}
              onChange={(_, n) => { getUserFun(n) }}
              sx={{
                ".MuiPagination-ul": { justifyContent: "end" },
                ".MuiPagination-ul button": {
                  width: "25px",
                  height: "25px",
                  lineHeight: "1",
                  minWidth: "auto",
                  fontFamily: "Poppins",
                }
              }}
            /> :
            <Stack direction="row" spacing={1}>
              {new Array(6).fill().map((_, i) => (
                <Skeleton
                  key={i}
                  sx={{
                    width: "30px",
                    height: "30px",
                    transform: "none"
                  }}
                />
              ))
              }
            </Stack>
          }
        </Box>
      </Box>

      <DocViewer
        show={show}
        setShow={setShow}
        documents={documents}
      />
    </Fragment>
  )
}

export default Agencies