import { Navigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { checkToken } from "./helper"

function AdminProtected({ children }) {
  const dispatch = useDispatch()

  return (
    checkToken(dispatch) ?
      children :
      <Navigate to="/login" replace />
  )
}

export default AdminProtected