import { hideDialog } from "../../../redux/guestSlices/other.slice"
import MuiIcons from "../../../assets/js/muiIcons"
import CustomSelect from "../../ui/CustomSelect"
import CustomInput from "../../ui/CustomInput"
import { useDispatch, useSelector } from "react-redux"

import {
  Button, CircularProgress, DialogActions,
  DialogContent, DialogTitle, Grid2 as Grid,
  IconButton, InputLabel, Paper, Skeleton, styled
} from "@mui/material"
import { Fragment, useEffect, useRef, useState } from "react"
import { handleGetAllRolesReq } from "../../../redux/adminSlices/role.slice"
import { handleAddAdminReq } from "../../../redux/adminSlices/subAdminReq.slice"

const Paragraph = styled("p")(() => ({}))

function SubAdminReq() {
  const role = useRef("")
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])

  const roleSlice = useSelector(state => state.roleSlice)
  const subAdminSlice = useSelector(state => state.subAdminSlice)

  const handleClose = () => {
    dispatch(hideDialog())
  }

  // Get all Role
  const getAllRoles = (page = null, search = null) => {
    dispatch(handleGetAllRolesReq({
      page,
      search
    })).unwrap().then((res) => {
      setRoles(res?.roles)
    })
  }

  // Handle submit 
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    formData.role = role?.current?.value?._id
    dispatch(handleAddAdminReq(formData))
  }

  useEffect(() => {
    getAllRoles()

    // eslint-disable-next-line
  }, [])

  return (
    <Paper component="form" onSubmit={handleSubmit}>
      <DialogTitle sx={{ position: "relative" }}>
        <Paragraph
          sx={{
            m: "0",
            fontSize: "20px",
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
        >Allow sub admin access
        </Paragraph>
        <IconButton
          sx={{
            top: "10px",
            right: "10px",
            color: "black",
            position: "absolute",
          }}
          size="small"
          onClick={handleClose}
        ><MuiIcons.ClearRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="relative"
        sx={{
          ".MuiInputBase-root": { borderRadius: "6px" },
        }}
      >
        <Grid container spacing={2}>
          <Grid size={12} sx={{ "input": { p: "2px" } }}>
            <CustomInput
              name="name"
              label="Name"
              value={""}
            />
          </Grid>
          <Grid size={12}>
            {roleSlice?.roleLoading ?
              <Fragment>
                <InputLabel
                  sx={{
                    mb: 0.5,
                    color: "#333333",
                    display: "block",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "start",
                    fontFamily: "Poppins",
                    textTransform: "capitalize",
                  }}
                >Role
                </InputLabel>
                <Skeleton
                  sx={{
                    width: "100%",
                    height: "35px",
                    transform: "none"
                  }}
                />
              </Fragment> :
              <CustomSelect
                ref={role}
                label="Role"
                select="Select"
                options={roles}
              />
            }
          </Grid>
          <Grid size={12} sx={{ "input": { p: "2px" } }}>
            <CustomInput
              value={""}
              name="email"
              type="email"
              label="email address"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          sx={{
            px: 2,
            color: "white",
            fontSize: "14px",
            fontWeight: "400",
            boxShadow: "none",
            minWidth: "150px",
            bgcolor: "#0135AD",
            borderRadius: "50px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          type="submit"
          endIcon={subAdminSlice?.submitLoading &&
            <CircularProgress
              size={20}
              thickness={5}
              sx={{ color: "white" }}
            />}
        >Invite
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default SubAdminReq