import { hideDialog, toastError, toastSuccess } from "../guestSlices/other.slice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { adminHeaders, BASEURI } from "../../utils/helper"
import axios from "axios"
import router from "../../routes/routes"

// Handle add sub admin request
export const handleAddAdminReq = createAsyncThunk("handleAddAdminReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/admin/access/admin/request`, data, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        setTimeout(() => {
          dispatch(hideDialog())
        }, 500)
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle accept sub admin request
export const handleAcceptAdminReq = createAsyncThunk("handleAddAdminReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/admin/access/admin/accept/${data?.id}`, { password: data?.password }, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        router.navigate("/")
        return res?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Handle accept sub admin request
export const handleCheckLinkExpiryReq = createAsyncThunk("", (id, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.get(`${BASEURI}/admin/access/admin/check-request/${id}`).then((res) => {
      if (res?.data?.success) {
        return res?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})


const initialState = {}
const subAdminReq = createSlice({
  name: "subAdminReq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Create role request
    builder.addCase(handleAddAdminReq.pending, (state) => {
      state.submitLoading = true
    })
    builder.addCase(handleAddAdminReq.fulfilled, (state) => {
      delete state.submitLoading
    })
    builder.addCase(handleAddAdminReq.rejected, (state) => {
      delete state.submitLoading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default subAdminReq.reducer