import logo from "./logo.png"
import image1 from "./image1.png"
import image2 from "./image2.png"
import image3 from "./image3.png"
import appStore from "./app-store.png"
import playStore from "./play-store.png"

const Images = {
  logo,
  image1,
  image2,
  image3,
  appStore,
  playStore,
}

export default Images