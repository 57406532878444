import { toastError, toastSuccess } from "../guestSlices/other.slice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { adminHeaders, BASEURI } from "../../utils/helper"
import axios from "axios"


// Create role request
export const handleCreateRoleReq = createAsyncThunk("handleCreateRoleReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/admin/role/create`, data, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get all roles request
export const handleGetAllRolesReq = createAsyncThunk("handleGetAllRolesReq", ({ page, search }, { rejectWithValue, dispatch }) => {
  const params = {}
  if (page) { params.page = page }
  if (search) { params.search = search }
  try {
    const response = axios.get(`${BASEURI}/admin/role/get-all`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get single roles request
export const handleGetSingleRole = createAsyncThunk("handleGetAllRolesReq", (url, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.get(`${BASEURI}/admin/role/get/${url}`, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.role
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get all roles request
export const handleUpdateRolesReq = createAsyncThunk("handleCreateRoleReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.put(`${BASEURI}/admin/role/update/${data?.url}`, { permissions: data?.permissions }, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  role: {},
  singleRole: {},
}
const roles = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Create role request
    builder.addCase(handleCreateRoleReq.pending, (state) => {
      state.role.loadingCreate = true
    })
    builder.addCase(handleCreateRoleReq.fulfilled, (state) => {
      delete state?.role?.loadingCreate
    })
    builder.addCase(handleCreateRoleReq.rejected, (state) => {
      delete state?.role?.loadingCreate
    })

    // Get all role request
    builder.addCase(handleGetAllRolesReq.pending, (state) => {
      state.role.loadingAll = true
    })
    builder.addCase(handleGetAllRolesReq.fulfilled, (state) => {
      delete state?.role?.loadingAll
    })
    builder.addCase(handleGetAllRolesReq.rejected, (state) => {
      delete state?.role?.loadingAll
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default roles.reducer