import VerificationRejection from "./dialogs/VerificationRejection"
import ProfileRejection from "./dialogs/ProfileRejection"
import AddSubCategory from "./dialogs/AddSubCategory"
import DeleteCategory from "./dialogs/DeleteCategory"
import EditCategory from "./dialogs/Editcategory"
import SubAdminReq from "./dialogs/SubAdminReq"
import AddCategory from "./dialogs/AddCategory"
import CreateRole from "./dialogs/CreateRole"
import { useSelector } from "react-redux"
import { Dialog } from "@mui/material"
import DeleteAdmin from "./dialogs/DeleteAdmin"

function DialogContainer() {
  const { show, type } = useSelector(state => state.otherSlice)

  return (
    <Dialog
      fullWidth
      open={show}
      maxWidth="xs"
      scroll="paper"
      id="#MuiDialog"
      sx={{
        ".MuiPaper-root": { borderRadius: "12px" },
        "& .MuiBackdrop-root": {
          opacity: "0.5 !important",
          backgroundColor: "#0135AD"
        },
      }}
    >
      {type === "CreateRole" && <CreateRole />}
      {type === "DeleteAdmin" && <DeleteAdmin />}
      {type === "AddCategory" && <AddCategory />}
      {type === "SubAdminReq" && <SubAdminReq />}
      {type === "EditCategory" && <EditCategory />}
      {type === "AddSubCategory" && <AddSubCategory />}
      {type === "DeleteCategory" && <DeleteCategory />}
      {type === "ProfileRejection" && <ProfileRejection />}
      {type === "VerificationRejection" && <VerificationRejection />}
    </Dialog>
  )
}

export default DialogContainer