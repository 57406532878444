import ReactSlick from "../../../components/common/ReactSlick"
import MuiIcons from "../../../assets/js/muiIcons"

import {
  Box, Button, Dialog, DialogContent, Grid2 as Grid,
  IconButton, Paper, Stack, styled
} from "@mui/material"

const Span = styled("span")(() => ({}))
const Paragraph = styled("p")(() => ({}))
function SingleBooking({ show, setShow }) {

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Dialog
      fullWidth
      open={show}
      maxWidth="xxl"
      scroll="paper"
      id="#MuiDialog"
      sx={{
        ".MuiPaper-root": { borderRadius: "12px" },
        "& .MuiBackdrop-root": {
          opacity: "0.5 !important",
          backgroundColor: "#0135AD"
        },
      }}
    >
      <Paper>
        <DialogContent sx={{ position: "relative" }}>
          <IconButton
            sx={{
              top: "0",
              right: "0",
              color: "black",
              position: "absolute",
            }}
            size="small"
            onClick={handleClose}
          ><MuiIcons.ClearRounded />
          </IconButton>

          {/* Image and details */}
          <Box sx={{ display: "flex", gap: "12px", flexWrap: { xs: "wrap", xl: "initial" } }}>
            <Box
              sx={{
                maxWidth: "450px",
                "slick-list": {
                  overflow: "hidden !important",
                  borderRadius: "12px !important",
                }
              }}
            >
              <ReactSlick />
            </Box>

            <Box>

              {/* Titles section */}
              <Paragraph
                sx={{
                  m: "0",
                  fontSize: "20px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                }}
              >Room cleaning
              </Paragraph>
              <Paragraph
                sx={{
                  m: "0",
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                }}
              >Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.
              </Paragraph>

              {/* Rating section */}
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <MuiIcons.Star sx={{ color: "#F48D2E" }} />
                <Span
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Be Vietnam Pro"
                  }}
                >4.5/230
                </Span>
                <Span
                  sx={{
                    color: "#666666",
                    fontSize: "13px",
                    fontWeight: "400",
                    fontFamily: "Be Vietnam Pro"
                  }}
                >Rating
                </Span>
              </Box>

              {/* Service Details */}
              <Box sx={{ border: "1px solid #E9E9E9", borderRadius: "8px", mt: 4 }}>
                <Paragraph
                  sx={{
                    m: "0",
                    py: 1,
                    px: 2,
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "Be Vietnam Pro",
                    borderBottom: "1px solid #E9E9E9"
                  }}
                >Service details
                </Paragraph>

                <Box sx={{ px: 2, py: 1 }}>
                  <Grid container spacing={3}>
                    <Grid size={3}>
                      <Paragraph
                        sx={{
                          m: "0",
                          fontSize: "13px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                        }}
                      >Location:
                      </Paragraph>
                      <Paragraph
                        sx={{
                          m: "0",
                          fontSize: "13px",
                          fontWeight: "400",
                          fontFamily: "Poppins",
                        }}
                      >PO Box 9255, Dubai, UAE
                      </Paragraph>
                    </Grid>
                    <Grid size={3}>
                      <Paragraph
                        sx={{
                          m: "0",
                          fontSize: "13px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                        }}
                      >Duration:
                      </Paragraph>
                      <Paragraph
                        sx={{
                          m: "0",
                          fontSize: "13px",
                          fontWeight: "400",
                          fontFamily: "Poppins",
                        }}
                      >3 hours
                      </Paragraph>
                    </Grid>
                    <Grid size={3}>
                      <Paragraph
                        sx={{
                          m: "0",
                          fontSize: "13px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                        }}
                      >Role
                      </Paragraph>
                      <Paragraph
                        sx={{
                          m: "0",
                          fontSize: "13px",
                          fontWeight: "400",
                          fontFamily: "Poppins",
                        }}
                      >individual provider
                      </Paragraph>
                    </Grid>
                    <Grid size={3}>
                      <Paragraph
                        sx={{
                          m: "0",
                          fontSize: "13px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                        }}
                      >Price
                      </Paragraph>
                      <Paragraph
                        sx={{
                          m: "0",
                          fontSize: "13px",
                          fontWeight: "400",
                          fontFamily: "Poppins",
                        }}
                      >$320
                      </Paragraph>
                    </Grid>
                  </Grid>
                </Box>

              </Box>

              {/* Action buttons */}
              <Box sx={{ mt: 4 }}>
                <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                  <Button
                    size="small"
                    sx={{
                      p: "5px",
                      color: "white",
                      lineHeight: "1",
                      fontSize: "14px",
                      minWidth: "90px",
                      fontWeight: "400",
                      minHeight: "auto",
                      bgcolor: "#0BCB41",
                      borderRadius: "20px",
                      fontFamily: "Poppins",
                      textTransform: "unset",
                    }}
                  >Approve
                  </Button>
                  <Button
                    size="small"
                    sx={{
                      p: "5px",
                      color: "white",
                      lineHeight: "1",
                      fontSize: "14px",
                      minWidth: "90px",
                      fontWeight: "400",
                      minHeight: "auto",
                      bgcolor: "#E40606",
                      borderRadius: "20px",
                      fontFamily: "Poppins",
                      textTransform: "unset",
                    }}
                  >Reject
                  </Button>
                </Stack>
              </Box>

            </Box>
          </Box>

          {/* Description */}
          <Box sx={{ mt: 4 }}>
            <Paragraph
              sx={{
                m: "0",
                mb: 1,
                fontSize: "18px",
                fontWeight: "500",
                fontFamily: "Be Vietnam Pro",
              }}
            >Description
            </Paragraph>
            <Paragraph
              sx={{
                m: "0",
                fontSize: "13px",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
            >Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.
              In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document.
            </Paragraph>
          </Box>
        </DialogContent>
      </Paper>
    </Dialog>
  )
}

export default SingleBooking