import { storeBtn, tBodyCell, tHeadCell, typography1, typography2 } from "../../assets/styles/styles"
import { getAllUsersReq, toggleUserStatusReq } from "../../redux/adminSlices/users.slice"
import { dashboardDataReq } from "../../redux/adminSlices/admin.slice"
import { buyersTableHeader } from "../../constants/admin/Tables"
import { Fragment, useEffect, useRef, useState } from "react"
import CustomSwitch from "../../components/ui/CustomSwitch"
import SearchInput from "../../components/ui/SearchInput"
import { useDispatch, useSelector } from "react-redux"
import { Users } from "../../assets/js/CustomIcons"
import Header from "../../components/admin/Header"
import Images from "../../assets/images"
import { Link } from "react-router-dom"
import moment from "moment"

import {
  Box, CircularProgress, Grid2 as Grid, Link as MuiLink,
  Pagination, Skeleton, Stack, styled, Table,
  TableBody, TableCell, TableContainer, TableHead,
  TableRow, Typography
} from "@mui/material"

const Span = styled("span")(() => ({}))
const Image = styled("img")(() => ({}))

function Dashboard() {
  const searchRef = useRef("")
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState({})
  const { admin } = useSelector(state => state.adminSlice)
  const { users, singleUser } = useSelector(state => state.usersSlice)
  const { dashboardData } = useSelector(state => state.adminSlice)

  const getAllUsers = (page = null, search = null) => {
    dispatch(getAllUsersReq({
      page,
      search
    })).unwrap().then((res) => {
      setData(res)
    })
  }

  let intervalId = null;
  const handleSearch = () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
    intervalId = setTimeout(() => {
      getAllUsers(null, searchRef?.current?.value?.toLowerCase())
    }, 2000)
  }

  const handleStatus = (e, id) => {
    setLoading(id)
    dispatch(toggleUserStatusReq({
      id, status: e.target.checked,
    })).unwrap().then(() => {
      getAllUsers()
    })
  }

  useEffect(() => {
    getAllUsers()
    dispatch(dashboardDataReq())

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Header>
        <Box>
          <SearchInput
            ref={searchRef}
            onChangeFun={handleSearch}
          />
        </Box>
      </Header>

      <Box
        sx={{
          px: { xs: 2, sm: 4 },
          py: { xs: 1, sm: 2 },
          bgcolor: "#f7f8f9",
          width: {
            xs: "calc(100vw - 6px)",
            laptop: "calc(100vw - (250px + 6px))"
          }
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            component="h5"
            sx={typography1}
          >Dashboard
          </Typography>

          {dashboardData?.loading ?
            <Grid container spacing={2}>
              {new Array(4).fill().map((_, i) => (
                <Grid key={i} size={{ xs: 12, sm: 6, lg: 4, xl: 3 }}>
                  <Skeleton sx={{ height: "125px", transform: "none" }} />
                </Grid>
              ))
              }
            </Grid> :
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 6, lg: 4, xl: 3 }}>
                <Box
                  sx={{
                    height: "100%",
                    bgcolor: "#321FDB",
                    borderRadius: "12px",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "12px", p: "12px", borderBottom: "1px solid #5140EB", alignItems: "center" }}>
                    <Box sx={{ display: "grid", placeContent: "center", height: "45px", width: "45px", borderRadius: "8px", bgcolor: "#5140EB" }}>
                      <Users fontSize={18} color="white" />
                    </Box>
                    <Box sx={{ flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >Total users
                      </Span>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins"
                        }}
                      >{dashboardData?.totalUsers}
                      </Span>
                    </Box>
                    <Box sx={{ alignSelf: "end" }}>
                      <MuiLink
                        component={Link}
                        sx={{
                          fontSize: "13px",
                          color: "white",
                          fontWeight: "400",
                          fontFamily: "Poppins",
                          textDecorationColor: "white"
                        }}
                        to="/users"
                      >View All</MuiLink>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ p: "12px", borderRight: "1px solid #5140EB", flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >Users
                      </Span>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins"
                        }}
                      >{dashboardData?.buyers}
                      </Span>
                    </Box>
                    <Box sx={{ p: "12px", borderRight: "1px solid #5140EB", flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >Individuals
                      </Span>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins"
                        }}
                      >{dashboardData?.individuals}
                      </Span>
                    </Box>
                    <Box sx={{ p: "12px", flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >Agency/Shop
                      </Span>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins"
                        }}
                      >{dashboardData?.agency}
                      </Span>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid size={{ xs: 12, sm: 6, lg: 4, xl: 3 }}>
                <Box
                  sx={{
                    height: "100%",
                    bgcolor: "#3399FE",
                    borderRadius: "12px",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "12px", p: "12px", borderBottom: "1px solid #5FAEFD", alignItems: "center" }}>
                    <Box sx={{ display: "grid", placeContent: "center", height: "45px", width: "45px", borderRadius: "8px", bgcolor: "#5FAEFD" }}>
                      <Users fontSize={18} color="white" />
                    </Box>
                    <Box sx={{ flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >Order overview
                      </Span>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins"
                        }}
                      >3000
                      </Span>
                    </Box>
                    <Box sx={{ alignSelf: "end" }}>
                      <MuiLink
                        sx={{
                          fontSize: "13px",
                          color: "white",
                          fontWeight: "400",
                          fontFamily: "Poppins",
                          textDecorationColor: "white"
                        }}
                        component={Link}
                        to="/bookings"
                      >View All</MuiLink>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ p: "12px", borderRight: "1px solid #5FAEFD", flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >Pending
                      </Span>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins"
                        }}
                      >50
                      </Span>
                    </Box>
                    <Box sx={{ p: "12px", borderRight: "1px solid #5FAEFD", flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >Completed
                      </Span>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins"
                        }}
                      >2500
                      </Span>
                    </Box>
                    <Box sx={{ p: "12px", flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >Cancelled
                      </Span>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins"
                        }}
                      >100
                      </Span>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid size={{ xs: 12, sm: 6, lg: 4, xl: 3 }}>
                <Box
                  sx={{
                    height: "100%",
                    bgcolor: "#F9B015",
                    borderRadius: "12px",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "12px", p: "12px", borderBottom: "1px solid #FEC64F", alignItems: "center" }}>
                    <Box sx={{ display: "grid", placeContent: "center", height: "45px", width: "45px", borderRadius: "8px", bgcolor: "#FEC64F" }}>
                      <Users fontSize={18} color="white" />
                    </Box>
                    <Box sx={{ flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >Categories
                      </Span>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins"
                        }}
                      >{dashboardData?.totalCategories}
                      </Span>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ p: "12px", borderRight: "1px solid #FEC64F", flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >Main-Categories
                      </Span>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Span
                          sx={{
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Poppins"
                          }}
                        >{dashboardData.mainCategories}
                        </Span>
                        <MuiLink
                          sx={{
                            fontSize: "13px",
                            color: "white",
                            fontWeight: "400",
                            fontFamily: "Poppins",
                            textDecorationColor: "white"
                          }}
                          component={Link}
                          to="/categories"
                        >View All
                        </MuiLink>
                      </Box>
                    </Box>
                    <Box sx={{ p: "12px", flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >Sub-Categories
                      </Span>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Span
                          sx={{
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Poppins"
                          }}
                        >{dashboardData?.subCategories}
                        </Span>
                        <MuiLink
                          sx={{
                            fontSize: "13px",
                            color: "white",
                            fontWeight: "400",
                            fontFamily: "Poppins",
                            textDecorationColor: "white"
                          }}
                          component={Link}
                          to="/sub-categories"
                        >View All
                        </MuiLink>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid size={{ xs: 12, sm: 6, lg: 4, xl: 3 }}>
                <Box
                  sx={{
                    height: "100%",
                    bgcolor: "#E55353",
                    borderRadius: "12px",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "12px", p: "12px", alignItems: "center" }}>
                    <Box sx={{ display: "grid", placeContent: "center", height: "45px", width: "45px", borderRadius: "8px", bgcolor: "#F57A7A" }}>
                      <Users fontSize={18} color="white" />
                    </Box>
                    <Box sx={{ flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >Verified users
                      </Span>
                      <Span
                        sx={{
                          color: "white",
                          display: "block",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins"
                        }}
                      >{dashboardData?.verifiedUsers}
                      </Span>
                    </Box>
                    <Box sx={{ alignSelf: "end" }}>
                      <MuiLink
                        component={Link}
                        sx={{
                          fontSize: "13px",
                          color: "white",
                          fontWeight: "400",
                          fontFamily: "Poppins",
                          textDecorationColor: "white"
                        }}
                        to="/profiles"
                      >View All</MuiLink>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          }

        </Box>

        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: { xs: "start", sm: "center" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Typography
            component="h6"
            sx={typography2}
          >All user list
          </Typography>

          {/* Total download count */}
          <Box sx={{ ml: "auto" }}>
            <Stack direction="row" spacing={1}>
              <Box sx={storeBtn}>
                <Image
                  sx={{
                    width: "18px",
                    height: "100%",
                    objectFit: "contain"
                  }}
                  src={Images?.playStore}
                />
                <Span
                  sx={{
                    color: "#333333",
                    fontSize: "14px",
                    lineHeight: "21px",
                    fontFamily: "Poppins",
                  }}
                >125 Play Store
                </Span>
              </Box>
              <Box sx={storeBtn}>
                <Image
                  sx={{
                    width: "18px",
                    height: "100%",
                    objectFit: "contain"
                  }}
                  src={Images?.appStore}
                />
                <Span
                  sx={{
                    color: "#333333",
                    fontSize: "14px",
                    lineHeight: "21px",
                    fontFamily: "Poppins",
                  }}
                >150 Play Store
                </Span>
              </Box>

              <MuiLink
                sx={{
                  color: "#0135AD",
                  fontSize: "15px",
                  lineHeight: "22.5px",
                  fontFamily: "Poppins",
                  textDecoration: "none",
                }}
                to="/users"
                component={Link}
              >View all users
              </MuiLink>
            </Stack>
          </Box>
        </Box>

        <Box sx={{ boxShadow: "0px 0px 7px 0px #20385833", p: 1, borderRadius: "8px" }}>
          <TableContainer
            sx={{
              bgcolor: "white",
              maxWidth: "100%",
              borderRadius: "8px",
              boxShadow: "none"
            }}
            className="custom-scroller-1"
          >
            <Table stickyHeader sx={{ "td,th": { borderBottom: "none" } }}>
              <TableHead
                sx={{
                  "th:last-child": { borderRadius: "0 6px 6px 0" },
                  "th:first-of-type": { borderRadius: "6px 0 0 6px" },
                }}
              >
                <TableRow>
                  {buyersTableHeader?.map((cell, i) => (
                    <TableCell
                      key={i}
                      align="left"
                      sx={tHeadCell}
                    >{cell}
                    </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.allLoading ?
                  new Array(5)?.fill()?.map((_, i) => (
                    <TableRow key={i}>
                      {new Array(6)?.fill()?.map((_, i) => (
                        <TableCell
                          key={i}
                          align="left"
                          sx={{
                            py: 0.5,
                            minWidth: "100px",
                          }}
                        ><Skeleton sx={{ height: "30px" }} />
                        </TableCell>
                      ))
                      }
                    </TableRow>
                  )) :
                  data?.users?.map((user, i) => (
                    <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell, textTransform: "capitalize" }}
                      >{user?.fullName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell }}
                      >{moment(user?.createdAt).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell, textTransform: "capitalize" }}
                      >{user?.userRole}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          ...tBodyCell,
                          color: user?.accountStatus ? "#1ACA4B" : "#4E5C6F"
                        }}
                      >{user?.accountStatus ? "Verified" : "Not Verified"}
                      </TableCell>
                      <TableCell sx={{ py: "0" }}>
                        {(singleUser?.sLoading && loading === user?._id) ?
                          <CircularProgress
                            size={25}
                            thickness={6}
                            sx={{
                              color: "#0135ad",
                              verticalAlign: "middle",
                              "span": { verticalAlign: "middle" }
                            }}
                          /> :
                          (admin?.role === "super-admin" || admin?.permissions?.users?.update) ?
                            <CustomSwitch
                              checked={user?.status}
                              onChange={(e) => { handleStatus(e, user?._id) }}
                            /> :
                            <CustomSwitch
                              disabled
                              checked={user?.status}
                            />
                        }
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell }}
                      >{user?.userRole === "buyer" ? "--" : 20}
                      </TableCell>
                    </TableRow>
                  ))
                }

                {(!data?.users?.length && !users?.allLoading) &&
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      sx={{
                        py: 1,
                        color: "#110e24",
                        fontSize: "24px",
                        minWidth: "100px",
                        fontWeight: "500",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                    >No results found!
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{
              py: 1,
              gap: "12px",
              display: "flex",
              justifyContent: "space-between",
              borderTop: "1px solid #D7D7D7",
            }}
          >
            <Typography
              sx={{
                ml: 3,
                color: "#333333",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
            >Showing {((data?.pages?.currentPage - 1) * 10 + 1) || 0} to {((data?.pages?.currentPage - 1) * 10 + data?.users?.length) || 0} of {data?.totalUsers || 0} entries
            </Typography>

            {!users?.allLoading ?
              <Pagination
                as="div"
                shape="circular"
                color="primary"
                boundaryCount={3}
                count={data?.pages?.totalPages || 1}
                page={+data?.pages?.currentPage || 1}
                onChange={(_, n) => { getAllUsers(n, searchRef?.current?.value.toLowerCase()) }}
                sx={{
                  ".MuiPagination-ul": { justifyContent: "end" },
                  ".MuiPagination-ul button": {
                    width: "25px",
                    height: "25px",
                    lineHeight: "1",
                    minWidth: "auto",
                    fontFamily: "Poppins",
                  }
                }}
              /> :
              <Stack direction="row" spacing={1}>
                {new Array(6).fill().map((_, i) => (
                  <Skeleton
                    key={i}
                    sx={{
                      width: "30px",
                      height: "30px",
                      transform: "none"
                    }}
                  />
                ))
                }
              </Stack>
            }
          </Box>
        </Box>

      </Box>
    </Fragment>
  )
}

export default Dashboard