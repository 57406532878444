import { getAllUsersReq } from "../../../redux/adminSlices/users.slice"
import { Fragment, useEffect, useRef, useState } from "react"
import SearchInput from "../../../components/ui/SearchInput"
import { typography1 } from "../../../assets/styles/styles"
import { Box, Tab, Tabs, Typography } from "@mui/material"
import Header from "../../../components/admin/Header"
import { useDispatch } from "react-redux"
import Individuals from "./Individuals"
import Agencies from "./Agencies"
import Buyers from "./Buyers"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function AllUsers() {
  const searchRef = useRef("")
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const [userRole, setUserRole] = useState("buyer")

  // Handle tab change
  const handleChange = (_, newValue) => {
    setUserRole(newValue)
  }

  // Handle get all users
  const getAllUsers = (page = null, search = null) => {
    dispatch(getAllUsersReq({
      page,
      search,
      userRole
    })).unwrap().then((res) => {
      setData(res)
    })
  }

  // Handle search users
  let intervalId = null;
  const handleSearch = () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
    intervalId = setTimeout(() => {
      getAllUsers(null, searchRef?.current?.value.toLowerCase())
    }, 2000)
  }

  useEffect(() => {
    getAllUsers()

    // eslint-disable-next-line
  }, [userRole])

  return (
    <Fragment>
      <Header>
        <Box>
          <SearchInput
            ref={searchRef}
            onChangeFun={handleSearch}
          />
        </Box>
      </Header>

      <Box
        sx={{
          px: { xs: 2, sm: 4 },
          py: { xs: 1, sm: 2 },
          bgcolor: "#f7f8f9",
          width: {
            xs: "calc(100vw - 6px)",
            laptop: "calc(100vw - (250px + 6px))"
          }
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            component="h5"
            sx={typography1}
          >View all users
          </Typography>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Tabs
            value={userRole}
            onChange={handleChange}
            sx={{
              overflow: "visible !important",
              ".MuiTabs-flexContainer": { gap: "3px" },
              ".MuiTabs-indicator": { display: "none" },
              ".MuiTabs-scroller": {
                overflow: "visible !important"
              },
              ".MuiTabs-scroller button": {
                color: "#4E5C6F",
                fontWeight: "400",
                textTransform: "unset",
                fontFamily: "Poppins",
                borderRadius: "6px 6px 0 0",
              },
              ".MuiTabs-scroller button.Mui-selected": {
                color: "#0135AD",
                fontWeight: "500",
                backgroundColor: "white",
                // boxShadow: "0px -2px 5px 0px #20385833",
                boxShadow: "0px 0px 7px 0px #20385833"
              },
            }}
          >
            <Tab value="buyer" label="Buyers" />
            <Tab value="individual" label="Individual provider" />
            <Tab value="agency" label="Agency's/Shop's" />
          </Tabs>
        </Box>

        <TabPanel value={userRole} index="buyer">
          <Buyers
            data={data}
            getUserFun={getAllUsers}
          />
        </TabPanel>
        <TabPanel value={userRole} index="individual">
          <Individuals
            data={data}
            getUserFun={getAllUsers}
          />
        </TabPanel>
        <TabPanel value={userRole} index="agency">
          <Agencies
            data={data}
            getUserFun={getAllUsers}
          />
        </TabPanel>
      </Box>
    </Fragment>
  )
}

export default AllUsers