import { earningTableHeader } from "../../../constants/admin/Tables"
import { tBodyCell, tHeadCell } from "../../../assets/styles/styles"
import moment from "moment"

import {
  Box, Pagination, Skeleton, Stack, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow
} from "@mui/material"

function Total({ data }) {
  const users = {}

  return (
    <TableContainer
      sx={{
        bgcolor: "white",
        maxWidth: "100%",
        borderRadius: "8px",
        borderTopLeftRadius: "0",
        boxShadow: "0px 0px 7px 0px #20385833"
      }}
    >
      <Table stickyHeader sx={{ p: 1, "td,th": { borderBottom: "none" } }}>
        <TableHead
          sx={{
            "th:last-child": { borderRadius: "0 6px 6px 0" },
            "th:first-of-type": { borderRadius: "6px 0 0 6px" },
          }}
        >
          <TableRow>
            {earningTableHeader?.map((cell, i) => (
              <TableCell
                key={i}
                align="left"
                sx={tHeadCell}
              >{cell}
              </TableCell>
            ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.allLoading ?
            new Array(5)?.fill()?.map((_, i) => (
              <TableRow key={i}>
                {new Array(6)?.fill()?.map((_, i) => (
                  <TableCell
                    key={i}
                    align="left"
                    sx={{
                      py: 0.5,
                      minWidth: "100px",
                    }}
                  ><Skeleton sx={{ height: "30px" }} />
                  </TableCell>
                ))
                }
              </TableRow>
            )) :
            [1, 1, 1, 1]?.map((user, i) => (
              // data?.users?.map((user, i) => (
              <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                <TableCell
                  align="left"
                  sx={tBodyCell}
                >Work together
                </TableCell>
                <TableCell
                  align="left"
                  sx={tBodyCell}
                >80$
                </TableCell>
                <TableCell
                  align="left"
                  sx={tBodyCell}
                >{moment(Date.now()).format("DD-MM-YYYY")}
                </TableCell>
              </TableRow>
            ))
          }

          {!(!data?.users?.length && !users?.allLoading) &&
            <TableRow>
              <TableCell
                colSpan={6}
                sx={{
                  py: 1,
                  color: "#110e24",
                  fontSize: "24px",
                  minWidth: "100px",
                  fontWeight: "500",
                  maxWidth: "250px",
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  textOverflow: "ellipsis",
                  textTransform: "capitalize",
                }}
              >No results found!
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>

      <Box sx={{ py: 1, borderTop: "1px solid #D7D7D7" }}>
        {!users?.allLoading ?
          <Pagination
            as="div"
            color="primary"
            shape="circular"
            boundaryCount={3}
            count={data?.pages?.totalPages || 1}
            page={+data?.pages?.currentPage || 1}
            // onChange={(_, n) => { getUsersByPage(n) }}
            sx={{
              ".MuiPagination-ul": { justifyContent: "end" },
              ".MuiPagination-ul button": {
                width: "25px",
                height: "25px",
                lineHeight: "1",
                minWidth: "auto",
                fontFamily: "Poppins",
              }
            }}
          /> :
          <Stack direction="row" spacing={1}>
            {new Array(6).fill().map((_, i) => (
              <Skeleton
                key={i}
                sx={{
                  width: "30px",
                  height: "30px",
                  transform: "none"
                }}
              />
            ))
            }
          </Stack>
        }
      </Box>
    </TableContainer>
  )
}

export default Total