import { createBrowserRouter, createRoutesFromElements } from "react-router-dom"
import AdminRoutes from "./admin.routes"

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {AdminRoutes}
    </>
  )
)

export default router