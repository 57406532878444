import DialogContainer from "../common/DialogContainer"
import { Outlet, useLocation } from "react-router-dom"
import Toaster from "../common/Toaster"
import { Box } from "@mui/material"
import { useState } from "react"
import Sidebar from "./Sidebar"

function Layout() {
  const { pathname } = useLocation()
  const [active, setActive] = useState(false)

  const sidebarActive = () => { setActive(true) }
  const sidebarDeActive = () => { setActive(false) }

  return (
    <Box
      sx={{
        display: "grid",
        minHeight: "100vh",
        gridTemplateRows: "auto 1fr auto"
      }}
    >
      {!(pathname === "/login" || pathname === "/" || pathname === "/accept-invitation") &&
        <Sidebar
          active={active}
          sidebarDeActive={sidebarDeActive}
        />
      }
      <Box
        sx={{
          display: "grid",
          minHeight: "100vh",
          transitionDuration: "350ms",
          gridTemplateRows: "auto 1fr",
          ml: {
            laptop: active ? "0" : !(pathname === "/login" || pathname === "/" || pathname === "/accept-invitation") ? "250px" : "0"
          }
        }}
        component="main"
        className="custom-scroller"
        onClick={() => { active && sidebarDeActive() }}
      >
        <Outlet context={{ active, sidebarActive }} />
      </Box>
      <Toaster />
      <DialogContainer />
    </Box>
  )
}

export default Layout