import { Search } from "../../assets/js/CustomIcons"
import { forwardRef, Fragment } from "react"
import { Input } from "@mui/material"

const SearchInput = forwardRef(({ onChangeFun }, ref) => {

  return (
    <Fragment>
      <Input
        sx={{
          p: "8px 8px",
          width: "100%",
          borderRadius: "6px",
          border: "1px solid #DADADA",
          ":after": { display: "none" },
          ":before": { display: "none" },

          "input": {
            p: "0",
            color: "#110e24",
            fontSize: "15px",
            fontFamily: "Poppins",
          },
          "svg": { mr: 1 }
        }}
        startAdornment={
          <Search fontSize={18} />
        }
        type="text"
        inputRef={ref}
        placeholder="Search"
        onChange={onChangeFun || null}
      />
    </Fragment>
  )
})

export default SearchInput