import { handleGetSingleRole, handleUpdateRolesReq } from "../../../redux/adminSlices/role.slice"
import { refresher } from "../../../redux/guestSlices/other.slice"
import { typography1 } from "../../../assets/styles/styles"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { roles } from "../../../constants/admin/Roles"
import { Fragment, useEffect, useState } from "react"
import Header from "../../../components/admin/Header"
import MuiIcons from "../../../assets/js/muiIcons"

import {
  Accordion, AccordionDetails, AccordionSummary,
  Box, Button, Checkbox, CircularProgress, FormControlLabel,
  Skeleton, Stack, Typography
} from "@mui/material"

function SingleRole() {
  const { url } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [expended, setExpended] = useState("users")
  const [singleRole, setSingleRole] = useState({})
  const { role } = useSelector(state => state.roleSlice)
  const { admin } = useSelector(state => state.adminSlice)
  const { refresh } = useSelector(state => state.otherSlice)

  // Expand role
  const handleExpand = (type) => {
    setExpended(type)
  }

  // Handle back button click
  const handleBack = () => {
    navigate(-1)
  }

  const handleGetRoll = () => {
    dispatch(handleGetSingleRole(url)
    ).unwrap().then((res) => {
      setSingleRole(res)
    })
  }

  //  Handle role update
  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const permissions = {}
    for (let [key, value] of formData.entries()) {
      const [group, action] = key.split(".")
      if (!permissions[group]) {
        permissions[group] = {}
      }
      permissions[group][action] = value === "on"
    }
    dispatch(handleUpdateRolesReq({ url, permissions })
    ).unwrap().then(() => {
      dispatch(refresher())
    })
  }

  useEffect(() => {
    if (!(admin?.role === "super-admin" || admin?.permissions?.roles?.update)) {
      navigate("/")
    }
    handleGetRoll()

    // eslint-disable-next-line
  }, [refresh])

  return (
    <Fragment>
      <Header />

      <Box
        sx={{
          px: { xs: 2, sm: 4 },
          py: { xs: 1, sm: 2 },
          bgcolor: "#f7f8f9",
          width: {
            xs: "calc(100vw - 6px)",
            laptop: "calc(100vw - (250px + 6px))"
          }
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            component="h5"
            sx={{
              ...typography1,
              textTransform: "capitalize"
            }}
          >{url.replaceAll("-", " ")}
          </Typography>
        </Box>

        <Box
          sx={{
            p: 3,
            bgcolor: "white",
            maxWidth: "100%",
            borderRadius: "8px",
            textTransform: "capitalize",
            boxShadow: "0px 0px 7px 0px #20385833",
            ".MuiPaper-root": {
              mb: "12px !important",
              borderRadius: "8px !important"
            }
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          {role?.loadingAll ?
            new Array(7).fill().map((_, i) => (
              <Skeleton
                key={i}
                sx={{
                  mb: 2,
                  height: "35px",
                  transform: "none",
                }}
              />
            )) :
            singleRole?.permissions && (
              <Fragment>
                {roles.map((roleItem, i) => (
                  <Accordion
                    key={i}
                    sx={{
                      boxShadow: "none",
                      fontFamily: "Poppins",
                      border: "1px solid #4E5C6F"
                    }}
                    expanded={expended === roleItem?.key}
                    onChange={() => { handleExpand(roleItem?.key); }}
                  >
                    <AccordionSummary
                      sx={{
                        fontWeight: "500",
                        minHeight: "auto !important",
                        ".MuiAccordionSummary-content": { my: "8px !important" }
                      }}
                      expandIcon={<MuiIcons.ArrowDropDownRounded sx={{ fontSize: "28px" }} />}
                    >
                      {roleItem?.name}
                    </AccordionSummary>
                    <AccordionDetails sx={{ fontWeight: "400" }}>
                      {Object.keys(singleRole.permissions[roleItem?.key]).map((key, j) => (
                        <Box key={j}>
                          <FormControlLabel
                            label={key}
                            name={`${roleItem?.key}.${key}`}
                            control={
                              <Checkbox
                                size="small"
                                defaultChecked={singleRole.permissions[roleItem?.key][key]}
                              />
                            }
                          />
                        </Box>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Fragment>
            )
          }

          <Stack direction="row" spacing={3} sx={{ mt: 4 }}>
            <Button
              sx={{
                fontSize: "15px",
                minWidth: "120px",
                borderRadius: "50px",
                fontFamily: "Poppins",
                textTransform: "none",
                boxShadow: "none !important"
              }}
              type="submit"
              variant="contained"
              disabled={role?.loadingCreate}
              endIcon={role?.loadingCreate &&
                <CircularProgress
                  size={20}
                  thickness={5}
                  sx={{ color: "white" }}
                />
              }
            >Save
            </Button>
            <Button
              sx={{
                fontSize: "15px",
                minWidth: "120px",
                borderRadius: "50px",
                fontFamily: "Poppins",
                textTransform: "none",
                boxShadow: "none !important"
              }}
              type="button"
              variant="contained"
              onClick={handleBack}
            >Back
            </Button>
          </Stack>
        </Box>

      </Box>
    </Fragment>
  )
}

export default SingleRole