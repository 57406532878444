import { tBodyCell, tHeadCell, typography1 } from "../../../assets/styles/styles"
import { subscriptionsTableHeader } from "../../../constants/admin/Tables"
import { getAllUsersReq } from "../../../redux/adminSlices/users.slice"
import { Fragment, useEffect, useRef, useState } from "react"
import SearchInput from "../../../components/ui/SearchInput"
import { useDispatch, useSelector } from "react-redux"
import Header from "../../../components/admin/Header"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { uid } from "uid"

import {
  Box, Pagination, Skeleton, Stack, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography
} from "@mui/material"

function AllSubscription() {
  const searchRef = useRef("")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState({})
  const { admin } = useSelector(state => state.adminSlice)
  const { users } = useSelector(state => state.usersSlice)

  // Handle get all subscriptions
  const getAllSubscriptions = (page = null, search = null) => {
    dispatch(getAllUsersReq({
      page,
      search
    })).unwrap().then((res) => {
      setData(res)
    })
  }

  // Handle search subscriptions
  let intervalId = null;
  const handleSearch = () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
    intervalId = setTimeout(() => {
      getAllSubscriptions(null, searchRef?.current?.value?.toLowerCase())
    }, 2000)
  }

  useEffect(() => {
    if (!(admin?.role === "super-admin" || admin?.permissions?.subscriptions)) {
      navigate("/")
    }
    getAllSubscriptions()

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Header>
        <Box>
          <SearchInput
            ref={searchRef}
            onChangeFun={handleSearch}
          />
        </Box>
      </Header>

      <Box
        sx={{
          px: { xs: 2, sm: 4 },
          py: { xs: 1, sm: 2 },
          bgcolor: "#f7f8f9",
          width: {
            xs: "calc(100vw - 6px)",
            laptop: "calc(100vw - (250px + 6px))"
          }
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            component="h5"
            sx={typography1}
          >Subscriptions
          </Typography>
        </Box>

        <Box sx={{ boxShadow: "0px 0px 7px 0px #20385833", p: 1, borderRadius: "8px" }}>
          <TableContainer
            sx={{
              bgcolor: "white",
              maxWidth: "100%",
              boxShadow: "none",
              borderRadius: "8px",
            }}
            className="custom-scroller-1"
          >
            <Table stickyHeader sx={{ "td,th": { borderBottom: "none" } }}>
              <TableHead
                sx={{
                  "th:last-child": { borderRadius: "0 6px 6px 0" },
                  "th:first-of-type": { borderRadius: "6px 0 0 6px" },
                }}
              >
                <TableRow>
                  {subscriptionsTableHeader?.map((cell, i) => (
                    <TableCell
                      key={i}
                      align="left"
                      sx={tHeadCell}
                    >{cell}
                    </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.allLoading ?
                  new Array(5)?.fill()?.map((_, i) => (
                    <TableRow key={i}>
                      {new Array(7)?.fill()?.map((_, i) => (
                        <TableCell
                          key={i}
                          align="left"
                          sx={{
                            py: 0.5,
                            minWidth: "100px",
                          }}
                        ><Skeleton sx={{ height: "30px" }} />
                        </TableCell>
                      ))
                      }
                    </TableRow>
                  )) :
                  data?.users?.map((user, i) => (
                    <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell, textTransform: "capitalize" }}
                      >{user?.fullName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={tBodyCell}
                      >{user?.email}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={tBodyCell}
                      >{uid(14)}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell }}
                      >$30
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell }}
                      >{moment(user?.createdAt).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ ...tBodyCell, textTransform: "capitalize" }}
                      >Pro plan / {user?.accountStatus ? "monthy" : "annually"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          ...tBodyCell,
                          color: user?.accountStatus ? "#1ACA4B" : "#4E5C6F"
                        }}
                      >{user?.accountStatus ? "active" : "expired"}
                      </TableCell>
                    </TableRow>
                  ))
                }

                {(!data?.users?.length && !users?.allLoading) &&
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      sx={{
                        py: 1,
                        color: "#110e24",
                        fontSize: "24px",
                        minWidth: "100px",
                        fontWeight: "500",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                    >No results found!
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{
              py: 1,
              gap: "12px",
              display: "flex",
              justifyContent: "space-between",
              borderTop: "1px solid #D7D7D7",
            }}
          >
            <Typography
              sx={{
                ml: 3,
                color: "#333333",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
            >Showing {((data?.pages?.currentPage - 1) * 10 + 1) || 0} to {((data?.pages?.currentPage - 1) * 10 + data?.users?.length) || 0} of {data?.totalUsers || 0} entries
            </Typography>

            {!users?.allLoading ?
              <Pagination
                as="div"
                shape="circular"
                color="primary"
                boundaryCount={3}
                count={data?.pages?.totalPages || 1}
                page={+data?.pages?.currentPage || 1}
                onChange={(_, n) => { getAllSubscriptions(n, searchRef?.current?.value.toLowerCase()) }}
                sx={{
                  ".MuiPagination-ul": { justifyContent: "end" },
                  ".MuiPagination-ul button": {
                    width: "25px",
                    height: "25px",
                    lineHeight: "1",
                    minWidth: "auto",
                    fontFamily: "Poppins",
                  }
                }}
              /> :
              <Stack direction="row" spacing={1}>
                {new Array(6).fill().map((_, i) => (
                  <Skeleton
                    key={i}
                    sx={{
                      width: "30px",
                      height: "30px",
                      transform: "none"
                    }}
                  />
                ))
                }
              </Stack>
            }
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default AllSubscription