import { getUsersByProfileStatusReq } from "../../../redux/adminSlices/users.slice"
import { Box, Grid2 as Grid, styled, Tab, Tabs, Typography } from "@mui/material"
import { Fragment, useEffect, useRef, useState } from "react"
import SearchInput from "../../../components/ui/SearchInput"
import { typography1 } from "../../../assets/styles/styles"
import { Users } from "../../../assets/js/CustomIcons"
import { useDispatch, useSelector } from "react-redux"
import Header from "../../../components/admin/Header"
import VerifiedProfiles from "./VerifiedProfiles"
import PendingProfiles from "./PendingProfiles"
import { useNavigate } from "react-router-dom"
import RejectedProfiles from "./RejectedProfiles"

const typesOfProfiles = [
  { name: "Verified profiles", type: "verified" },
  { name: "Pending verification", type: "pending" },
  { name: "Rejected verification", type: "rejected" },
]

const Span = styled("span")(() => ({}))
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function AllProfiles() {
  const searchRef = useRef("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const [value, setValue] = useState("verified")
  const { users } = useSelector(state => state.usersSlice)
  const { admin } = useSelector(state => state.adminSlice)

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  // Get all users
  const getAllUsers = (page = null) => {
    dispatch(getUsersByProfileStatusReq({
      page,
      status: value,
      search: searchRef?.current?.value.toLowerCase() || null,
    })).unwrap().then((res) => {
      setData(res)
    })
  }

  // Hanldle search
  let intervalId = null;
  const handleSearch = () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
    intervalId = setTimeout(() => {
      getAllUsers(null, searchRef?.current?.value.toLowerCase())
    }, 2000)
  }

  useEffect(() => {
    if (!(admin?.role === "super-admin" || admin?.permissions?.verification)) {
      return navigate("/")
    }
    getAllUsers()

    // eslint-disable-next-line
  }, [value])

  return (
    <Fragment>
      <Header>
        <Box>
          <SearchInput
            ref={searchRef}
            onChangeFun={handleSearch}
          />
        </Box>
      </Header>

      <Box
        sx={{
          px: { xs: 2, sm: 4 },
          py: { xs: 1, sm: 2 },
          bgcolor: "#f7f8f9",
          width: {
            xs: "calc(100vw - 6px)",
            laptop: "calc(100vw - (250px + 6px))"
          }
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            mb={3}
            component="h5"
            sx={typography1}
          >Profile Verification
          </Typography>

          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 6, lg: 4, xl: 3 }}>
              <Box
                sx={{
                  bgcolor: "white",
                  borderRadius: "12px",
                  boxShadow: "0px 0px 7px 0px #20385833"
                }}
              >
                <Box sx={{ display: "flex", gap: "12px", p: "12px", alignItems: "center" }}>
                  <Box sx={{ display: "grid", placeContent: "center", height: "45px", width: "45px", borderRadius: "8px", bgcolor: "#E2E8F0" }}>
                    <Users fontSize={18} color="#0135AD" />
                  </Box>
                  <Box sx={{ flexGrow: "1" }}>
                    <Span
                      sx={{
                        color: "#666666",
                        display: "block",
                        fontSize: "12px",
                        fontFamily: "Poppins"
                      }}
                    >Verified profiles
                    </Span>
                    <Span
                      sx={{
                        color: "black",
                        display: "block",
                        fontSize: "18px",
                        fontWeight: "500",
                        fontFamily: "Poppins"
                      }}
                    >{data?.verified}
                    </Span>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4, xl: 3 }}>
              <Box
                sx={{
                  bgcolor: "white",
                  borderRadius: "12px",
                  boxShadow: "0px 0px 7px 0px #20385833"
                }}
              >
                <Box sx={{ display: "flex", gap: "12px", p: "12px", alignItems: "center" }}>
                  <Box sx={{ display: "grid", placeContent: "center", height: "45px", width: "45px", borderRadius: "8px", bgcolor: "#E2E8F0" }}>
                    <Users fontSize={18} color="#0135AD" />
                  </Box>
                  <Box sx={{ flexGrow: "1" }}>
                    <Span
                      sx={{
                        color: "#666666",
                        display: "block",
                        fontSize: "12px",
                        fontFamily: "Poppins"
                      }}
                    >Pending verification
                    </Span>
                    <Span
                      sx={{
                        color: "black",
                        display: "block",
                        fontSize: "18px",
                        fontWeight: "500",
                        fontFamily: "Poppins"
                      }}
                    >{data?.pending}
                    </Span>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4, xl: 3 }}>
              <Box
                sx={{
                  bgcolor: "white",
                  borderRadius: "12px",
                  boxShadow: "0px 0px 7px 0px #20385833"
                }}
              >
                <Box sx={{ display: "flex", gap: "12px", p: "12px", alignItems: "center" }}>
                  <Box sx={{ display: "grid", placeContent: "center", height: "45px", width: "45px", borderRadius: "8px", bgcolor: "#E2E8F0" }}>
                    <Users fontSize={18} color="#0135AD" />
                  </Box>
                  <Box sx={{ flexGrow: "1" }}>
                    <Span
                      sx={{
                        color: "#666666",
                        display: "block",
                        fontSize: "12px",
                        fontFamily: "Poppins"
                      }}
                    >Rejected verification
                    </Span>
                    <Span
                      sx={{
                        color: "black",
                        display: "block",
                        fontSize: "18px",
                        fontWeight: "500",
                        fontFamily: "Poppins"
                      }}
                    >{data?.rejected}
                    </Span>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              overflow: "visible !important",
              ".MuiTabs-flexContainer": { gap: "3px" },
              ".MuiTabs-indicator": { display: "none" },
              ".MuiTabs-scroller": {
                overflow: "visible !important"
              },
              ".MuiTabs-scroller button": {
                color: "#4E5C6F",
                fontWeight: "400",
                textTransform: "unset",
                fontFamily: "Poppins",
                borderRadius: "6px 6px 0 0",
              },
              ".MuiTabs-scroller button.Mui-selected": {
                color: "#0135AD",
                fontWeight: "500",
                backgroundColor: "white",
                boxShadow: "0px 0px 7px 0px #20385833"
              },
            }}
          >
            {typesOfProfiles?.map((type, i) => (
              <Tab key={i} value={type?.type} label={type?.name} />
            ))
            }
          </Tabs>
        </Box>

        <TabPanel value={value} index="verified">
          <VerifiedProfiles
            data={data}
            getUserFun={getAllUsers}
            loading={users?.allLoading}
          />
        </TabPanel>
        <TabPanel value={value} index="pending">
          <PendingProfiles
            data={data}
            getUserFun={getAllUsers}
            loading={users?.allLoading}
          />
        </TabPanel>
        <TabPanel value={value} index="rejected">
          <RejectedProfiles
            data={data}
            getUserFun={getAllUsers}
            loading={users?.allLoading}
          />
        </TabPanel>
      </Box>
    </Fragment>
  )
}

export default AllProfiles