import { toastError, toastSuccess } from "../guestSlices/other.slice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { adminHeaders, BASEURI } from "../../utils/helper"
import router from "../../routes/routes"
import { Cookies } from "react-cookie"
import axios from "axios"

const cookies = new Cookies()

// Handle admin login request
export const adminLoginReq = createAsyncThunk("adminLoginReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/admin/login`, data
    ).then((res) => {
      if (res.data?.success) {
        if (res.data.token) {
          cookies.set("token", res.data.token, { path: "/" })
          router?.navigate("/dashboard")
        }
        dispatch(toastSuccess(res.data?.message))
        return res.data?.user
      }
      if (!res.data?.success) {
        dispatch(toastError(res.data?.error))
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get dashboard data request
export const dashboardDataReq = createAsyncThunk("dashboardDataReq", (_, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.get(`${BASEURI}/admin/dashboard`, {
      headers: adminHeaders()
    }).then((res) => {
      if (res.data?.success) {
        return res.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get admin list request
export const allAdminListReq = createAsyncThunk("allAdminListReq", (data, { rejectWithValue, dispatch }) => {
  const params = {
    page: data?.page || null,
    search: data?.search || null,
  }
  try {
    const response = axios.get(`${BASEURI}/admin/sub-admin/get-all`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res.data?.success) {
        return res.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get admin request request
export const allAdminRequestReq = createAsyncThunk("allAdminListReq", (data, { rejectWithValue, dispatch }) => {
  const params = {
    page: data?.page || null,
    search: data?.search || null,
  }
  try {
    const response = axios.get(`${BASEURI}/admin/sub-admin/get-all-requests`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res.data?.success) {
        return res.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get admin update request
export const allAdminUpdateReq = createAsyncThunk("allAdminListReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/admin/sub-admin/update-requests/${data?.id}`, data?.formData, {
      headers: adminHeaders()
    }).then((res) => {
      if (res.data?.success) {
        dispatch(toastSuccess(res.data?.message))
        return res.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get admin update request
export const subAdminDeleteReq = createAsyncThunk("allAdminListReq", (id, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.delete(`${BASEURI}/admin/sub-admin/delete/${id}`, {
      headers: adminHeaders()
    }).then((res) => {
      if (res.data?.success) {
        dispatch(toastSuccess(res.data?.message))
        return res.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get admin update request
export const uploadProfilePicReq = createAsyncThunk("uploadProfilePicReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/admin/upload/profile-picture`, data, {
      headers: {
        ...adminHeaders(),
        "Content-Type": "multipart/form-data",
      }
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log({ error })
  }
})

// Update profile
export const updateProfileReq = createAsyncThunk("adminLoginReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/admin/update/profile`, data, {
      headers: adminHeaders()
    }).then((res) => {
      if (res.data?.success) {
        dispatch(toastSuccess(res.data?.message))
        return res.data.admin
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  admin: {},
  dashboardData: {}
}
const admin = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle admin login request
    builder.addCase(adminLoginReq.pending, (state) => {
      state.admin.loading = true
    })
    builder.addCase(adminLoginReq.fulfilled, (state, { payload }) => {
      delete state.admin.loading
      state.admin = payload || {}
    })
    builder.addCase(adminLoginReq.rejected, (state) => {
      delete state.admin.loading
    })
    // Handle admin login request
    builder.addCase(uploadProfilePicReq.pending, (state) => {
      state.admin.imgLoading = true
    })
    builder.addCase(uploadProfilePicReq.fulfilled, (state) => {
      delete state.admin.imgLoading
    })
    builder.addCase(uploadProfilePicReq.rejected, (state) => {
      delete state.admin.imgLoading
    })

    // Get dashboard data request
    builder.addCase(dashboardDataReq.pending, (state) => {
      state.dashboardData.loading = true
    })
    builder.addCase(dashboardDataReq.fulfilled, (state, { payload }) => {
      delete state.dashboardData.loading
      state.dashboardData = payload || {}
    })
    builder.addCase(dashboardDataReq.rejected, (state) => {
      delete state.dashboardData.loading
    })

    // Get all admins request
    builder.addCase(allAdminListReq.pending, (state) => {
      state.loading = true
    })
    builder.addCase(allAdminListReq.fulfilled, (state, { payload }) => {
      delete state.loading
    })
    builder.addCase(allAdminListReq.rejected, (state) => {
      delete state.loading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default admin.reducer