import { hideDialog, toastError } from "../../../redux/guestSlices/other.slice"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../../../assets/js/muiIcons"
import CustomInput from "../../ui/CustomInput"
import { useParams } from "react-router-dom"

import {
  handleGetAllCategoriesReq,
  handleUpdateSingleCategoryReq
} from "../../../redux/adminSlices/category.slice"

import {
  Button, CircularProgress, DialogActions,
  DialogContent, DialogTitle, FormControl, Grid2 as Grid,
  IconButton, InputLabel, MenuItem, OutlinedInput, Paper, Select, Skeleton, styled
} from "@mui/material"

const Paragraph = styled("p")(() => ({}))

function EditCategory() {
  const dispatch = useDispatch()
  const { id: categoryId } = useParams()
  const [parent, setParent] = useState({})
  const [categories, setCategories] = useState([])
  const { data } = useSelector(state => state.otherSlice)
  const categorySlice = useSelector(state => state.categorySlice)
  // let defaultVal={}

  const handleClose = () => {
    dispatch(hideDialog())
  }

  // Handle submit
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    formData.parentCategory = parent?._id
    if (formData?.name?.field?.trim() === "") {
      return dispatch(toastError("Name is required!"))
    }
    if (categoryId) {
      if (!formData?.parentCategory) {
        return dispatch(toastError("Parent category is required!"))
      }
    }

    dispatch(handleUpdateSingleCategoryReq({ id: data?.category?._id, formData }))
  }

  // Handle get all parent categories
  const getAllCategories = () => {
    const selectedCategory = data?.category
    dispatch(handleGetAllCategoriesReq({
      page: null,
      search: null
    })).unwrap().then((data) => {
      const filteredCategories = data?.categories?.filter(
        (category) => category._id !== selectedCategory?._id
      )
      const parentCategory = data?.categories?.find(
        (category) => category._id !== data?.category?.parentCategory
      )

      setParent(parentCategory)
      setCategories(filteredCategories)
    })
  }

  // Handle change
  const handleOnChange = (e) => {
    setParent(e?.target?.value)
  }

  useEffect(() => {
    if (categoryId) {
      getAllCategories()
    }

    // eslint-disable-next-line
  }, [])


  return (
    <Paper component="form" onSubmit={handleSubmit}>
      <DialogTitle sx={{ position: "relative" }}>
        <Paragraph
          sx={{
            m: "0",
            fontSize: "20px",
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
        >Edit category
        </Paragraph>
        <IconButton
          sx={{
            top: "10px",
            right: "10px",
            color: "black",
            position: "absolute",
          }}
          size="small"
          onClick={handleClose}
        ><MuiIcons.ClearRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="relative"
        sx={{
          ".MuiInputBase-root": { borderRadius: "6px" },
        }}
      >
        <Grid container spacing={2}>
          <Grid size={12} sx={{ "input": { p: "2px" } }}>
            <CustomInput
              type="text"
              name="name"
              label="Name"
              value={data?.category?.name || null}
            />
          </Grid>
          {categoryId &&
            <Grid size={12}>
              {categorySlice?.categoriesLoading ?
                <Fragment>
                  <InputLabel
                    sx={{
                      mb: 0.5,
                      color: "#333333",
                      display: "block",
                      fontSize: "14px",
                      fontWeight: "400",
                      textAlign: "start",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                    }}
                  >Select parent category
                  </InputLabel>
                  <Skeleton
                    sx={{
                      width: "100%",
                      height: "35px",
                      transform: "none"
                    }}
                  />
                </Fragment> :
                <FormControl size="small" fullWidth>
                  <Select
                    input={<OutlinedInput
                      // id={id}
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DADADA",
                        },
                        ".MuiSelect-select": {
                          py: "6px",
                          fontSize: "14px",
                          color: "#999999",
                          textTransform: "capitalize"
                        }
                      }}
                    />}
                    value={parent}
                    onChange={(e, f) => { handleOnChange(e) }}
                    renderValue={(option) => option.name || "Select"}
                  >
                    <MenuItem
                      disabled
                      value="[object Object]"
                      sx={{
                        fontSize: "14px",
                        color: "#999999",
                      }}
                    >Select
                    </MenuItem>
                    {categories.map((option, i) => (
                      <MenuItem
                        key={i}
                        value={option}
                        sx={{
                          color: "black",
                          fontSize: "14px",
                          textTransform: "capitalize"
                        }}
                      >{option?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
              {/* <CustomSelect
                select="Select"
                ref={parentCategory}
                options={categories}
                label="Select parent category"
                value={categories?.find((category) => category?._id === data?.category?.parentCategory) || {}}
              /> */}
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          sx={{
            px: 2,
            color: "white",
            fontSize: "14px",
            fontWeight: "400",
            boxShadow: "none",
            minWidth: "150px",
            bgcolor: "#0135AD",
            borderRadius: "50px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          type="submit"
          endIcon={categorySlice?.submitLoading && <CircularProgress
            size={20}
            thickness={5}
            sx={{ color: "white" }}
          />}
        >Update
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default EditCategory