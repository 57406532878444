export const roles = [
  { name: "users", key: "users" },
  { name: "bookings", key: "bookings" },
  { name: "categories", key: "categories" },
  { name: "earnings", key: "earnings" },
  { name: "verification", key: "verification" },
  { name: "subscriptions", key: "subscriptions" },
  { name: "transactions", key: "transactions" },
  { name: "withdrawal", key: "withdrawal" },
  { name: "admins", key: "admins" },
  { name: "admin requests", key: "adminRequests" },
  { name: "roles", key: "roles" },
]