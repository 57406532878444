import { Box, Button, CircularProgress, Grid2 as Grid, styled } from "@mui/material"
import CustomInput from "../../components/ui/CustomInput"
import Header from "../../components/admin/Header"
import MuiIcons from "../../assets/js/muiIcons"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useState } from "react"
import { updateProfileReq, uploadProfilePicReq } from "../../redux/adminSlices/admin.slice"

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

function Profile() {
  const dispatch = useDispatch()
  const [image, setImage] = useState("")
  const { admin } = useSelector(state => state.adminSlice)

  const uploadImage = (file) => {
    dispatch(uploadProfilePicReq({ file })
    ).unwrap().then((res) => {
      setImage(res?.fileURL)
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    formData.image = image
    dispatch(updateProfileReq(formData))
  }

  return (
    <Fragment>
      <Header />

      <Box sx={{ p: 3, width: { xs: "100vw", laptop: "calc(100vw - 250px)" } }}>
        {/* <Grid container spacing={4} sx={{ alignItems: "center" }}>
          <Grid size={{ xs: "auto" }}>
            <Avatar
              sx={{
                color: "primary.main",
                bgcolor: "#DADADA",
                width: { xs: "75px", md: "150px" },
                height: { xs: "75px", md: "150px" },
              }}
            />
          </Grid>
          <Grid size={{ xs: "auto" }}>
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "Poppins",
                textTransform: "capitalize",
                fontSize: { xs: "24px", md: "32px" },
              }}
              component="h2"
            >{admin?.fullName}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: { xs: "16px", md: "18px" },
              }}
            >{admin?.email}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
              }}
            >Status:&nbsp;
              <Chip
                sx={{
                  "span": {
                    color: "white",
                    lineHeight: "1",
                    fontSize: "12px",
                    overflow: "unset",
                    fontFamily: "Poppins",
                    textTransform: "capitalize",
                  },
                  py: "2px",
                  height: "auto",
                  bgcolor: "primary.main"
                }}
                size="small"
                component="span"
                label={admin?.status ? "active" : "deactive"}
              />
            </Typography>
          </Grid>
        </Grid> */}

        <Box sx={{ py: 3 }}>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <CustomInput
                  name="fullName"
                  label="full name"
                  value={admin?.fullName}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <CustomInput
                  name="email"
                  type="email"
                  label="email"
                  value={admin?.email}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <CustomInput
                  name="phone"
                  label="phone"
                  value={admin?.phone}
                />
              </Grid>
              {/* <Grid size={{ xs: 12, md: 6 }}>
                <CustomInput
                  name="password"
                  label="password"
                  type="password"
                  value={admin?.password}
                />
              </Grid> */}
              <Grid size={{ xs: 12 }}>
                <Button
                  tabIndex={-1}
                  role={undefined}
                  component="label"
                  variant="contained"
                  startIcon={
                    admin?.imgLoading ?
                      <CircularProgress
                        size={25}
                        thickness={5}
                        sx={{ color: "white" }}
                      /> :
                      <MuiIcons.CloudUpload />
                  }
                  sx={{
                    fontFamily: "Poppins",
                    textTransform: "unset",
                    boxShadow: "none !important",
                  }}
                  disabled={admin?.imgLoading}
                >{"Profile photo"}
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => {
                      uploadImage(event.target.files[0])
                      event.target.value = ""
                    }}
                    multiple
                  />
                </Button>
                <Button
                  sx={{
                    ml: 1,
                    fontFamily: "Poppins",
                    textTransform: "unset",
                    boxShadow: "none !important",
                  }}
                  type="submit"
                  variant="contained"
                  // onClick={handleSubmit}
                  endIcon={admin?.loading &&
                    <CircularProgress
                      size={25}
                      thickness={5}
                      sx={{ color: "white" }}
                    />
                  }
                  disabled={admin?.loading || admin?.imgLoading}
                >Update
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default Profile