import "slick-carousel/slick/slick-theme.css"
import CustomImage from "../ui/CustomImage"
import Images from "../../assets/images"
import "slick-carousel/slick/slick.css"
import Slider from "react-slick"
import { Box } from "@mui/material"

function ReactSlick() {
  const settings = {
    // autoplay: true,
    initialSlide: 0,
    autoplaySpeed: 0,
    dots: true,
    // infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  return (
    <Slider {...settings}>
      <Box sx={{ px: 1 }}>
        <CustomImage
          wrapperCss={{
            width: "100%",
            height: "100%",
          }}
          imgCss={{
            width: "100%",
            height: "100%",
          }}
          src={Images.image2}
        />
      </Box>
      <Box sx={{ px: 1 }}>
        <CustomImage
          wrapperCss={{
            width: "100%",
            height: "100%",
          }}
          imgCss={{
            width: "100%",
            height: "100%",
          }}
          src={Images.image2}
        />
      </Box>
    </Slider>
  )
}

export default ReactSlick
