import { hideDialog, refresher, toastError, toastSuccess } from "../guestSlices/other.slice"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { adminHeaders, BASEURI } from "../../utils/helper"
import axios from "axios"

// Create category request
export const handleCreateCategoryReq = createAsyncThunk("handleCreateCategoryReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/admin/category/create`, data, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(refresher())
        dispatch(toastSuccess(res?.data?.message))
        setTimeout(() => {
          dispatch(hideDialog())
        }, 500)
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get all categories request
export const handleGetAllCategoriesReq = createAsyncThunk("handleGetAllCategoriesReq", ({ page, search }, { rejectWithValue, dispatch }) => {
  const params = {}
  if (page) { params.page = page }
  if (search) { params.search = search }
  try {
    const response = axios.get(`${BASEURI}/admin/category/get-all`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get all categories request
export const handleGetAllSubCategoriesReq = createAsyncThunk("handleGetAllCategoriesReq", ({ page, search }, { rejectWithValue, dispatch }) => {
  const params = {}
  if (page) { params.page = page }
  if (search) { params.search = search }
  try {
    const response = axios.get(`${BASEURI}/admin/category/get-all-subcategory`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get single category request
export const handleGetSingleCategoryReq = createAsyncThunk("handleGetSingleCategoryReq", (data, { rejectWithValue, dispatch }) => {
  const params = {
    page: data?.page || null,
    search: data?.search || null,
  }
  try {
    const response = axios.get(`${BASEURI}/admin/category/get/${data?.id}`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Update single category request
export const handleUpdateSingleCategoryReq = createAsyncThunk("handleCreateCategoryReq", ({ id, formData }, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.put(`${BASEURI}/admin/category/update/${id}`, formData, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(refresher())
        dispatch(toastSuccess(res?.data?.message))
        setTimeout(() => {
          dispatch(hideDialog())
        }, 500)
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Delete single category request
export const handleDeleteSingleCategoryReq = createAsyncThunk("handleCreateCategoryReq", (id, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.delete(`${BASEURI}/admin/category/delete/${id}`, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(refresher())
        dispatch(toastSuccess(res?.data?.message))
        setTimeout(() => {
          dispatch(hideDialog())
        }, 500)
      }
      return res?.data
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Toggle user status request
export const toggleCategoryStatusReq = createAsyncThunk("toggleCategoryStatusReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.put(`${BASEURI}/admin/category/update-status/${data?.id}`, { status: data?.status }, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  category: {}
}
const category = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Create category request
    builder.addCase(handleCreateCategoryReq.pending, (state) => {
      state.submitLoading = true
    })
    builder.addCase(handleCreateCategoryReq.fulfilled, (state) => {
      delete state?.submitLoading
    })
    builder.addCase(handleCreateCategoryReq.rejected, (state) => {
      delete state?.submitLoading
    })

    // Get all categories request
    builder.addCase(handleGetAllCategoriesReq.pending, (state) => {
      state.categoriesLoading = true
    })
    builder.addCase(handleGetAllCategoriesReq.fulfilled, (state) => {
      delete state?.categoriesLoading
    })
    builder.addCase(handleGetAllCategoriesReq.rejected, (state) => {
      delete state?.categoriesLoading
    })

    // Get single category request
    builder.addCase(handleGetSingleCategoryReq.pending, (state) => {
      state.category.loading = true
    })
    builder.addCase(handleGetSingleCategoryReq.fulfilled, (state, { payload }) => {
      delete state?.category?.loading
      state.category = payload
    })
    builder.addCase(handleGetSingleCategoryReq.rejected, (state) => {
      delete state?.category?.loading
    })
  }
})

export default category.reducer