import { Box, Grid2 as Grid, styled, Tab, Tabs, Typography } from "@mui/material"
import { typesOfEarning } from "../../../constants/admin/Earnings"
import SearchInput from "../../../components/ui/SearchInput"
import { typography1 } from "../../../assets/styles/styles"
import { Doller } from "../../../assets/js/CustomIcons"
import Header from "../../../components/admin/Header"
import { Fragment, useEffect, useRef, useState } from "react"
import Total from "./Total"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"


const Span = styled("span")(() => ({}))
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function Earnings() {
  const searchRef = useRef("")
  const navigate = useNavigate()
  const [value, setValue] = useState(1)
  const { admin } = useSelector(state => state.adminSlice)

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (!(admin?.role === "super-admin" || admin?.permissions?.earnings)) {
      return navigate("/")
    }

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Header>
        <Box>
          <SearchInput
            ref={searchRef}
          // onChangeFun={handleSearch}
          />
        </Box>
      </Header>

      <Box
        sx={{
          px: { xs: 2, sm: 4 },
          py: { xs: 1, sm: 2 },
          bgcolor: "#f7f8f9",
          width: {
            xs: "calc(100vw - 6px)",
            laptop: "calc(100vw - (250px + 6px))"
          }
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            component="h5"
            sx={typography1}
          >Earning
          </Typography>

          <Grid container spacing={2}>
            {typesOfEarning?.map((type, i) => (
              <Grid key={i} size={{ xs: 12, sm: 6, lg: 4, xl: 3 }}>
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: "12px",
                    boxShadow: "0px 0px 7px 0px #20385833"
                  }}
                >
                  <Box sx={{ display: "flex", gap: "12px", p: "12px", alignItems: "center" }}>
                    <Box sx={{ display: "grid", placeContent: "center", height: "45px", width: "45px", borderRadius: "8px", bgcolor: "#E2E8F0" }}>
                      <Doller fontSize={26} color="#0135AD" />
                    </Box>
                    <Box sx={{ flexGrow: "1" }}>
                      <Span
                        sx={{
                          color: "#666666",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "Poppins"
                        }}
                      >{type}
                      </Span>
                      <Span
                        sx={{
                          color: "black",
                          display: "block",
                          fontSize: "18px",
                          fontWeight: "500",
                          fontFamily: "Poppins"
                        }}
                      >$1680
                      </Span>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))
            }
          </Grid>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              overflow: "visible !important",
              ".MuiTabs-flexContainer": { gap: "3px" },
              ".MuiTabs-indicator": { display: "none" },
              ".MuiTabs-scroller": {
                overflow: "visible !important"
              },
              ".MuiTabs-scroller button": {
                color: "#4E5C6F",
                fontWeight: "400",
                textTransform: "unset",
                fontFamily: "Poppins",
                borderRadius: "6px 6px 0 0",
              },
              ".MuiTabs-scroller button.Mui-selected": {
                color: "#0135AD",
                fontWeight: "500",
                backgroundColor: "white",
                boxShadow: "0px 0px 7px 0px #20385833"
              },
            }}
          >
            {typesOfEarning?.map((type, i) => (
              <Tab key={i} value={i + 1} label={type} />
            ))
            }
            {/* <Tab value={2} label="Individual provider" />
          <Tab value={3} label="Agency's/Shop's" /> */}
          </Tabs>
        </Box>

        <TabPanel value={value} index={1}>
          <Total />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Total />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Total />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Total />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Total />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Total />
        </TabPanel>
      </Box>
    </Fragment>
  )
}

export default Earnings