import { Fragment, useEffect, useRef, useState } from "react"
import SearchInput from "../../../components/ui/SearchInput"
import Header from "../../../components/admin/Header"
import { useNavigate } from "react-router-dom"
import SingleBooking from "./SingleBooking"
import Images from "../../../assets/images"
import { useSelector } from "react-redux"

import {
  Avatar, Box, Button, Grid2 as Grid, Stack,
  styled, Typography
} from "@mui/material"

// Custom components
const Span = styled("span")(() => ({}))
const Paragraph = styled("p")(() => ({}))

// Components start
function Bookings() {
  const searchRef = useRef("")
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const { admin } = useSelector(state => state.adminSlice)

  useEffect(() => {
    if (!(admin?.role === "super-admin" || admin?.permissions?.bookings)) {
      return navigate("/")
    }

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Header>
        <Box>
          <SearchInput
            ref={searchRef}
          // onChangeFun={handleSearch}
          />
        </Box>
      </Header>

      <Box sx={{ p: 3, width: { xs: "calc(100vw)", laptop: "calc(100vw - (250px + 16px))" } }}>
        <Box
          sx={{
            mb: 3,
            display: "flex",
            alignItems: { xs: "start", sm: "center" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: "18px",
              fontWeight: "500",
              textWrap: "nowrap",
              fontFamily: "Poppins",
            }}
          >Bookings list
          </Typography>
          <Box
            sx={{
              maxWidth: "350px",
              mt: { xs: 1, sm: "auto" },
              ml: { xs: "unset", sm: "auto" },
            }}
          >
          </Box>
        </Box>

        <Box sx={{ p: 3, borderRadius: "12px", boxShadow: "0px 0px 7px 0px #20385833" }}>
          <Grid container spacing={2}>
            {new Array(4).fill().map((_, i) => (
              <Grid size={12} key={i}>
                <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                  <Box sx={{ display: "flex", flexGrow: "1", gap: "4px" }}>
                    <Avatar
                      src={Images?.image1}
                      sx={{ height: "30px", width: "30px" }}
                    />
                    <Box>
                      <Paragraph
                        sx={{
                          m: "0",
                          fontSize: "14px",
                          fontFamily: "Poppins",
                        }}
                      >User Name
                      </Paragraph>
                      <Paragraph
                        sx={{
                          m: "0",
                          color: "#4E5C6F",
                          fontSize: "13px",
                          fontFamily: "Poppins",
                        }}
                      >Room cleaning service
                        <Span
                          sx={{
                            ml: 2,
                            color: "#0135AD",
                            fontSize: "13px",
                            fontWeight: "500",
                            // fontFamily: "Poppins",
                          }}
                        >$320
                        </Span>
                      </Paragraph>
                    </Box>
                  </Box>
                  <Box>
                    <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                      <Button
                        size="small"
                        sx={{
                          p: "5px",
                          color: "white",
                          lineHeight: "1",
                          fontSize: "14px",
                          minWidth: "90px",
                          fontWeight: "400",
                          minHeight: "auto",
                          bgcolor: "#0BCB41",
                          borderRadius: "20px",
                          fontFamily: "Poppins",
                          textTransform: "unset",
                        }}
                      >Approve
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          p: "5px",
                          color: "white",
                          lineHeight: "1",
                          fontSize: "14px",
                          minWidth: "90px",
                          fontWeight: "400",
                          minHeight: "auto",
                          bgcolor: "#E40606",
                          borderRadius: "20px",
                          fontFamily: "Poppins",
                          textTransform: "unset",
                        }}
                      >Reject
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          p: "5px",
                          color: "white",
                          lineHeight: "1",
                          fontSize: "14px",
                          minWidth: "90px",
                          fontWeight: "400",
                          minHeight: "auto",
                          bgcolor: "#0135AD",
                          borderRadius: "20px",
                          fontFamily: "Poppins",
                          textTransform: "unset",
                        }}
                        onClick={() => { setShow(true) }}
                      >View
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            ))
            }
          </Grid>
        </Box>

        {/* <Box sx={{ py: 1 }}>
        {!users?.allLoading ?
          <Pagination
            as="div"
            color="#00cada"
            shape="circular"
            boundaryCount={3}
            count={data?.pages?.totalPages || 4}
            page={+data?.pages?.currentPage || 1}
            // onChange={(_, n) => { getUsersByPage(n) }}
            sx={{
              ".MuiPagination-ul": { justifyContent: "end" },
              ".MuiPagination-ul button": {
                width: "25px",
                height: "25px",
                lineHeight: "1",
                minWidth: "auto",
                fontFamily: "Poppins",
                color: !true ? "white" : "",
                bgcolor: !true ? "#0135AD" : "",
              },
              ".MuiPagination-ul li:nth-child(2) button": {
                color: true ? "white" : "",
                bgcolor: true ? "#0135AD" : "",
              }
            }}
          /> :
          <Stack direction="row" spacing={1}>
            {new Array(6).fill().map((_, i) => (
              <Skeleton
                key={i}
                sx={{
                  width: "30px",
                  height: "30px",
                  transform: "none"
                }}
              />
            ))
            }
          </Stack>
        }
      </Box> */}
      </Box>

      <SingleBooking show={show} setShow={setShow} />
    </Fragment>
  )
}

export default Bookings