import { Link, useNavigate, useOutletContext } from "react-router-dom"
import { Bell, Menu as MenuIcon } from "../../assets/js/CustomIcons"
import { showDialog } from "../../redux/guestSlices/other.slice"
import { useDispatch, useSelector } from "react-redux"
import { Cookies } from "react-cookie"
import { useState } from "react"
import Images from "../../assets/images"

import {
  Avatar, Badge, Box, Button, Divider,
  IconButton, Menu, MenuItem, styled
} from "@mui/material"

const Span = styled("span")(() => ({}))
function Header({ children }) {
  const cookies = new Cookies()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sidebarActive } = useOutletContext()
  const [anchorEl, setAnchorEl] = useState(false)
  const handleClose = () => { setAnchorEl(null) }
  const { admin } = useSelector(state => state.adminSlice)
  const handleClick = (event) => { setAnchorEl(event.currentTarget) }

  const open = Boolean(anchorEl)

  const handleLogout = () => {
    cookies.remove("token", { path: "/" })
    dispatch({ type: "Logout", payload: "" })
    navigate("/login")
  }

  const handleAdminAccess = () => {
    dispatch(showDialog("SubAdminReq"))
  }

  return (
    <Box
      sx={{
        px: 2,
        gap: 1,
        py: 1.5,
        display: "flex",
        alignItems: "center",
      }}
      component="header"
    >
      <Box
        sx={{
          gap: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          size="small"
          onClick={sidebarActive}
        ><MenuIcon fontSize={18} color="#4E5C6F" />
        </IconButton>
        {children}
      </Box>
      <Box sx={{ flexGrow: "1" }} />

      {(admin?.role === "super-admin" || admin?.permissions?.admins?.create) &&
        <Button
          sx={{
            px: 2,
            color: "white",
            fontSize: "14px",
            fontWeight: "400",
            boxShadow: "none",
            bgcolor: "#0135AD",
            borderRadius: "50px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          onClick={handleAdminAccess}
        >Allow sub admin access
        </Button>
      }

      <IconButton
        size="small"
      >
        <Badge
          badgeContent={3}
          sx={{
            ".MuiBadge-badge": {
              p: "0",
              width: "15px",
              height: "15px",
              color: "white",
              fontSize: "12px",
              minWidth: "unset",
              bgcolor: "#0135AD",
            }
          }}
        >
          <Bell fontSize={18} color="#4E5C6F" />
        </Badge>
      </IconButton>

      <IconButton
        size="small"
        sx={{ p: "2px" }}
        onClick={handleClick}
      >
        <Avatar
          src={Images?.image3}
          sx={{ width: 35, height: 35 }}
        />
      </IconButton>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "12px",
            boxShadow: "0px 0px 10px 0px #20385880"
          }
        }}
      >
        <MenuItem
          sx={{
            minWidth: "180px",
            flexDirection: "column"
          }}
        >
          <Span
            sx={{
              width: "100%",
              fontSize: "14px",
              color: "#4E5C6F",
              fontWeight: "400",
              fontFamily: "Poppins"
            }}
          >Peter John
          </Span>
          <Span
            sx={{
              width: "100%",
              fontSize: "11px",
              color: "#4E5C6F",
              fontWeight: "400",
              fontFamily: "Poppins"
            }}
          >test@hmail.com
          </Span>
        </MenuItem>
        <Divider sx={{ m: "0 !important" }} />
        <MenuItem
          as={Link}
          to="/profile"
          // onClick={handleClose}
          sx={{ minWidth: "120px" }}
        ><Span
          sx={{
            fontSize: "14px",
            color: "#4E5C6F",
            fontWeight: "400",
            fontFamily: "Poppins"
          }}
        >Profile
          </Span>
        </MenuItem>
        <Divider sx={{ m: "0 !important" }} />
        <MenuItem
          as={Link}
          // to="/profile/me"
          onClick={handleClose}
          sx={{ minWidth: "120px" }}
        ><Span
          sx={{
            fontSize: "14px",
            color: "#4E5C6F",
            fontWeight: "400",
            fontFamily: "Poppins"
          }}
        >Settings
          </Span>
        </MenuItem>
        <Divider sx={{ m: "0 !important" }} />
        <MenuItem
          onClick={handleLogout}
          sx={{ minWidth: "120px" }}
        ><Span
          sx={{
            fontSize: "14px",
            color: "#4E5C6F",
            fontWeight: "400",
            fontFamily: "Poppins"
          }}
        >Logout
          </Span>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default Header