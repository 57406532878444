import { handleCreateCategoryReq } from "../../../redux/adminSlices/category.slice"
import { hideDialog, toastError } from "../../../redux/guestSlices/other.slice"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../../../assets/js/muiIcons"
import CustomInput from "../../ui/CustomInput"


import {
  Button, CircularProgress, DialogActions,
  DialogContent, DialogTitle, Grid2 as Grid,
  IconButton, Paper, styled
} from "@mui/material"

const Paragraph = styled("p")(() => ({}))

function AddCategory() {
  const dispatch = useDispatch()
  const categorySlice = useSelector(state => state.categorySlice)

  const handleClose = () => {
    dispatch(hideDialog())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    if (!formData?.name?.trim()) {
      return dispatch(toastError("Name is required!"))
    }
    dispatch(handleCreateCategoryReq(formData))
  }

  return (
    <Paper component="form" onSubmit={handleSubmit}>
      <DialogTitle sx={{ position: "relative" }}>
        <Paragraph
          sx={{
            m: "0",
            fontSize: "20px",
            fontWeight: "500",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
        >Add new category
        </Paragraph>
        <IconButton
          sx={{
            top: "10px",
            right: "10px",
            color: "black",
            position: "absolute",
          }}
          size="small"
          onClick={handleClose}
        ><MuiIcons.ClearRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="relative"
        sx={{
          ".MuiInputBase-root": { borderRadius: "6px" },
        }}
      >
        <Grid container spacing={2}>
          <Grid size={12} sx={{ "input": { p: "2px" } }}>
            <CustomInput
              type="text"
              name="name"
              label="Name"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          sx={{
            px: 2,
            color: "white",
            fontSize: "14px",
            fontWeight: "400",
            boxShadow: "none",
            minWidth: "150px",
            bgcolor: "#0135AD",
            borderRadius: "50px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          type="submit"
          endIcon={categorySlice?.submitLoading && <CircularProgress
            size={20}
            thickness={5}
            sx={{ color: "white" }}
          />}
        >Create
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default AddCategory