import { Box, Dialog, DialogContent, IconButton, Paper } from "@mui/material"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import MuiIcons from "../../../assets/js/muiIcons"
import Slider from "react-slick"
import ImageWithSkell from "../../../components/common/ImageWithSkell"

function DocViewer({ show, setShow, documents }) {
  const settings = {
    initialSlide: 0,
    autoplaySpeed: 0,
    // dots: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: !true,
    infinite: false,
  }

  // Close dialoge
  const handleClose = () => {
    setShow(false)
  }

  const handleDownload = async (doc) => {
    try {
      const response = await fetch(doc?.fileURL);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = doc?.fileName || "downloaded-file";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed", error);
    }
  }

  return (
    <Dialog
      fullWidth
      open={show}
      maxWidth="xxl"
      scroll="paper"
      id="#MuiDialog"
      sx={{
        ".MuiPaper-root": { borderRadius: "12px", height: "100%" },
        "& .MuiBackdrop-root": {
          opacity: "0.5 !important",
          backgroundColor: "#0135AD"
        },
      }}
    // sx={{ ".MuiPaper-root": { height: "100%" } }}
    >
      <Paper>
        <DialogContent sx={{ position: "relative", height: "100%", "div": { height: "100%" } }}>
          <IconButton
            sx={{
              top: "0",
              right: "0",
              color: "black",
              position: "absolute",
            }}
            size="small"
            onClick={handleClose}
          ><MuiIcons.ClearRounded />
          </IconButton>

          <Box
            sx={{
              height: "100%",
              maxWidth: "100%",
              maxHeight: "100%",
              "slick-list": {
                overflow: "hidden !important",
                borderRadius: "12px !important",
              }
            }}
          >
            <Slider {...settings}>
              {documents?.map((doc, i) => (
                doc?.fileType !== "pdf" ?
                  <Box sx={{ position: "relative" }}>
                    <IconButton
                      sx={{
                        top: "10px",
                        left: "10px",
                        position: "absolute",
                        color: "primary.main",
                        bgcolor: "white !important",
                      }}
                      size="small"
                      onClick={() => { handleDownload(doc) }}
                    ><MuiIcons.DownloadRounded />
                    </IconButton>
                    <ImageWithSkell
                      key={i}
                      src={doc?.fileURL}
                    />
                  </Box> :
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      flexDirection: "column",
                      display: "flex !important",
                      "embed": {
                        width: "100%",
                        height: "100%",
                        border: "none",
                        flexGrow: "1"
                      }
                    }}
                    key={i}
                  >
                    <IconButton
                      sx={{
                        top: "10px",
                        left: "10px",
                        position: "absolute",
                        color: "primary.main",
                        bgcolor: "white !important",
                      }}
                      size="small"
                      onClick={() => { handleDownload(doc) }}
                    ><MuiIcons.DownloadRounded />
                    </IconButton>
                    <embed
                      type="application/pdf"
                      src={`${doc?.fileURL}#toolbar=0&navpanes=0&scrollbar=0`}
                    />
                  </Box>
              ))
              }
            </Slider>
          </Box>
        </DialogContent>
      </Paper>
    </Dialog>
  )
}

export default DocViewer