import AllSubscription from "../pages/admin/subscriptions/AllSubscription"
import AllTransactions from "../pages/admin/transactions/AllTransactions"
import AllSubCategories from "../pages/admin/categories/AllSubCategories"
import SingleCategory from "../pages/admin/categories/SingleCategory"
import Withdrawals from "../pages/admin/withdrawals/Withdrawals"
import AdminRequest from "../pages/admin/subAdmins/AdminRequest"
import AllCategory from "../pages/admin/categories/AllCategory"
import AcceptInvitation from "../pages/admin/AcceptInvitation"
import AllAdmins from "../pages/admin/subAdmins/AllAdmins"
import SingleRole from "../pages/admin/roles/SingleRole"
import Profiles from "../pages/admin/profiles/Profiles"
import Bookings from "../pages/admin/bookings/Bookings"
import Earnings from "../pages/admin/earnings/Earnings"
import AllUsers from "../pages/admin/users/AllUsers"
import AdminProtected from "../utils/AdminProtected"
import AllRoles from "../pages/admin/roles/AllRoles"
import Dashboard from "../pages/admin/Dashboard"
import Layout from "../components/admin/Layout"
import Profile from "../pages/admin/Profile"
import { Route } from "react-router-dom"
import Login from "../pages/admin/Login"

const AdminRoutes = (
  <Route path="/" element={<Layout />}>
    <Route path="" element={<Login />} />
    <Route path="login" element={<Login />} />
    <Route path="accept-invitation" element={<AcceptInvitation />} />

    {/* Admin protected */}
    <Route path="users" element={<AdminProtected><AllUsers /></AdminProtected>} />
    <Route path="roles" element={<AdminProtected><AllRoles /></AdminProtected>} />
    <Route path="profile" element={<AdminProtected><Profile /></AdminProtected>} />
    <Route path="admins" element={<AdminProtected><AllAdmins /></AdminProtected>} />
    <Route path="earnings" element={<AdminProtected><Earnings /></AdminProtected>} />
    <Route path="profiles" element={<AdminProtected><Profiles /></AdminProtected>} />
    <Route path="bookings" element={<AdminProtected><Bookings /></AdminProtected>} />
    <Route path="dashboard" element={<AdminProtected><Dashboard /></AdminProtected>} />
    <Route path="role/:url" element={<AdminProtected><SingleRole /></AdminProtected>} />
    <Route path="categories" element={<AdminProtected><AllCategory /></AdminProtected>} />
    <Route path="withdrawals" element={<AdminProtected><Withdrawals /></AdminProtected>} />
    <Route path="admin-request" element={<AdminProtected><AdminRequest /></AdminProtected>} />
    <Route path="category/:id" element={<AdminProtected><SingleCategory /></AdminProtected>} />
    <Route path="transactions" element={<AdminProtected><AllTransactions /></AdminProtected>} />
    <Route path="subscriptions" element={<AdminProtected><AllSubscription /></AdminProtected>} />
    <Route path="sub-categories" element={<AdminProtected><AllSubCategories /></AdminProtected>} />
  </Route>
)

export default AdminRoutes