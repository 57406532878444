import { toastHide } from "../../redux/guestSlices/other.slice"
import { Alert, Slide, Snackbar } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

const SlideComponet = (props) => (
  <Slide {...props} direction="left" />
)

function Toaster() {
  const dispatch = useDispatch()
  const { toast } = useSelector(state => state.otherSlice)

  const handleClose = () => {
    dispatch(toastHide())
  }

  return (
    <Snackbar
      open={toast?.show}
      onClose={handleClose}
      autoHideDuration={2000}
      TransitionComponent={SlideComponet}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={toast?.severity || "success"}
        sx={{ bgcolor: "#0135AD", border: "1px solid white" }}
      >{toast?.msg}
      </Alert>
    </Snackbar>
  )
}

export default Toaster