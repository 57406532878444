import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { adminHeaders, BASEURI } from "../../utils/helper";
import { toastError, toastSuccess } from "../guestSlices/other.slice";

// Get all withdrawals
export const getAllWithdrawalsReq = createAsyncThunk("getAllWithdrawalsReq", (data, { rejectWithValue, dispatch }) => {
  const params = {
    page: data.page || null,
    search: data.search || null,
  }
  try {
    const response = axios.get(`${BASEURI}/admin/withdrawal/get-all`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Update withdrawal
export const updateWithdrawalReq = createAsyncThunk("getAllWithdrawalsReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/admin//withdrawal/update/${data?.id}`, data?.formData, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  withdrawals: {}
}
const withdrawal = createSlice({
  name: "withdrawal",
  initialState,
  extraReducer: (builder) => {
    // Get all withdrawals
    builder.addCase(getAllWithdrawalsReq.pending, (state) => {
      state.withdrawals.loading = true
    })
    builder.addCase(getAllWithdrawalsReq.fulfilled, (state) => {
      delete state?.withdrawals?.loading
    })
    builder.addCase(getAllWithdrawalsReq.rejected, (state) => {
      delete state?.withdrawals?.loading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default withdrawal.reducer