import { Link as NavLink, useLocation, useNavigate } from "react-router-dom"
import { Box, Button, Link, Stack, styled } from "@mui/material"
import { LogoutIcon } from "../../assets/js/CustomIcons"
import { menus } from "../../constants/admin/Sidebar"
import { useDispatch, useSelector } from "react-redux"
import Images from "../../assets/images"
import { Cookies } from "react-cookie"

const Image = styled("img")(() => ({}))
const SidebarContainer = styled("div")(() => ({
  top: "0",
  bottom: "0",
  zIndex: "200",
  width: "250px",
  color: "white",
  height: "100vh",
  position: "fixed",
  overflowY: "auto",
  backgroundColor: "#0135ad",
  transitionDuration: "350ms",
}))

function Sidebar({ active, sidebarDeActive }) {
  const cookies = new Cookies()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { admin } = useSelector(state => state.adminSlice)

  const handleLogout = () => {
    cookies.remove("token", { path: "/" })
    dispatch({ type: "Logout", payload: "" })
    navigate("/login")
  }

  return (
    <SidebarContainer
      as="aside"
      className="custom-scroller"
      sx={{
        left: {
          xs: active ? "0" : "-100%",
          laptop: active ? "-100%" : "0"
        }
      }}
    >
      <Box sx={{ textAlign: "center", pb: 0.5, pt: 1 }}>
        <Box sx={{ py: "53px" }}>
          <Link
            to="/dashboard"
            component={NavLink}
            sx={{ display: "inline-block" }}
          >
            <Image
              sx={{
                height: "50px",
                width: "100%",
                objectFit: "contain"
              }}
              alt="logo"
              src={Images?.logo}
            />
          </Link>
        </Box>
      </Box>

      <Stack sx={{ px: 2 }} spacing={1}>
        {menus?.map((menu, i) => (
          ((admin?.role === "super-admin") || menu?.key === "dashboard" ||
            admin?.permissions?.hasOwnProperty(menu?.key)) &&
          <Button
            sx={{
              color: "white",
              fontSize: "14px",
              fontWeight: "400",
              borderRadius: "12px",
              fontFamily: "Poppins",
              justifyContent: "start",
              textTransform: "capitalize",
              boxShadow: "none !important",
              ":hover": pathname !== menu?.path && { bgcolor: "#1D4CB9" },
              bgcolor: pathname === menu?.path ? "#1D4CB9" : "transparent",
            }}
            key={i}
            to={menu?.path}
            variant="contained"
            startIcon={menu?.icon}
            LinkComponent={NavLink}
            onClick={sidebarDeActive}
          >{menu?.name}
          </Button>
        ))
        }
        <Button
          sx={{
            color: "white",
            fontSize: "14px",
            fontWeight: "400",
            borderRadius: "12px",
            bgcolor: "transparent",
            fontFamily: "Poppins",
            justifyContent: "start",
            textTransform: "capitalize",
            boxShadow: "none !important",
            ":hover": { bgcolor: "#1D4CB9" },
          }}
          variant="contained"
          onClick={handleLogout}
          startIcon={<LogoutIcon fontSize={18} />}
        >Logout
        </Button>
      </Stack>
    </SidebarContainer >
  )
}

export default Sidebar