import { adminLoginReq } from "../../redux/adminSlices/admin.slice"
import { toastError } from "../../redux/guestSlices/other.slice"
import CustomInput from "../../components/ui/CustomInput"
import { useDispatch, useSelector } from "react-redux"
import { checkToken } from "../../utils/helper"
import { useNavigate } from "react-router-dom"
import Images from "../../assets/images"
import { useEffect } from "react"

import {
  Box, Button, CircularProgress, Container, Grid2 as Grid,
  ListItem, ListItemText, Paper, styled,
} from "@mui/material"

const Image = styled("img")(() => ({}))
const Paragraph = styled("p")(() => ({}))
function Login() {
  let deviceId
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { admin } = useSelector(state => state.adminSlice)

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    formData.deviceId = deviceId
    if ([formData?.email, formData?.password]?.some(
      (field) => field?.trim() === ""
    )) {
      return dispatch(toastError("Please fill all required field!"))
    }
    dispatch(adminLoginReq(formData))
  }

  const x = () => {
    fetch("https://api.ipify.org?format=json")
      .then(response => {
        return response.json();
      }, "jsonp")
      .then(res => {
        deviceId = res.ip
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    x()
    const isLogin = checkToken(dispatch)
    if (isLogin) {
      return navigate("/dashboard")
    }

    // eslint-disable-next-line
  }, [])

  return (
    <Box
      component="section"
      sx={{
        height: "100%",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <Box
        sx={{
          top: "0",
          left: "0",
          right: "0",
          zIndex: "-1",
          minHeight: "50vh",
          bgcolor: "#0135AD",
          position: "absolute",
          clipPath: "polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 0 75%)",
        }}
      />
      <Container sx={{ height: "100%" }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            py: 4,
            height: "100%",
            display: "grid",
            placeContent: "center",
            position: "relative"
          }}
        >
          <Image
            alt="logo"
            src={Images?.logo}
            sx={{ mb: 5, mx: "auto" }}
          />
          <Box
            sx={{
              p: 3,
              maxWidth: "380px",
              borderRadius: "30px",
              boxShadow: "0px 0px 4px 0px #00000040"
            }}
            component={Paper}
          >
            <ListItem sx={{ justifyContent: "center", mb: 2 }}>

              <ListItemText sx={{ flexGrow: "unset", m: "0" }}>
                <Paragraph
                  sx={{
                    m: "0",
                    color: "#0135AD",
                    fontSize: "24px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                >Admin Login
                </Paragraph>
              </ListItemText>
            </ListItem>
            <Grid container spacing={2}>
              <Grid size={12}>
                <CustomInput
                  name="email"
                  type="email"
                  label="email"
                />
              </Grid>
              <Grid size={12}>
                <CustomInput
                  name="password"
                  type="password"
                  label="password"
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Button
              sx={{
                width: "100%",
                color: "white",
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "none",
                bgcolor: "#0135AD",
                borderRadius: "50px",
                fontFamily: "Poppins",
                textTransform: "capitalize",
              }}
              type="submit"
            >Login
              {admin?.loading &&
                <CircularProgress
                  size={25}
                  thickness={5}
                  sx={{
                    ml: 1,
                    display: "flex",
                    color: "white"
                  }}
                />
              }
            </Button>
          </Box>

        </Box>
      </Container>
    </Box>
  )
}

export default Login