import {
  Menu,
  Star,
  AddRounded,
  CloudUpload,
  ClearRounded,
  EditOutlined,
  LogoutRounded,
  SearchRounded,
  DownloadRounded,
  GridViewOutlined,
  DateRangeRounded,
  VerifiedOutlined,
  DashboardOutlined,
  HandshakeOutlined,
  ErrorOutlineRounded,
  PeopleOutlineRounded,
  ArrowDropDownRounded,
  RemoveRedEyeOutlined,
  DeleteForeverOutlined,
  MonetizationOnOutlined,
  NotificationsNoneOutlined,
} from "@mui/icons-material"

const MuiIcons = {
  Menu,
  Star,
  AddRounded,
  CloudUpload,
  ClearRounded,
  EditOutlined,
  LogoutRounded,
  SearchRounded,
  DownloadRounded,
  GridViewOutlined,
  DateRangeRounded,
  VerifiedOutlined,
  DashboardOutlined,
  HandshakeOutlined,
  ErrorOutlineRounded,
  PeopleOutlineRounded,
  ArrowDropDownRounded,
  RemoveRedEyeOutlined,
  DeleteForeverOutlined,
  MonetizationOnOutlined,
  NotificationsNoneOutlined,
}

export default MuiIcons